import React from "react";
import "./index.css";

const BlockTitle = ({ title }) => {
  return (
    <h2 className="block-title">
      <span className="title-angle-shape">{title}</span>
    </h2>
  );
};

export default BlockTitle;
