import Acceuil from "../Accueil/accueil";
import EmissionPage2 from "../Emission/emissionpage";
import TVPage from "../TV/tv";
import Guide from "../Guide/guide";
import Actualite from "../Actualite";
import NewsDetail from "../Actualite/detail";
import SliderPage from "../Slider/slider";
import LiveVideoDetail from "../DernierVideo";
import RadioPage from "../Radio/radio";
import Radio from "../Radio";
import PageNotFound from "../Common/404";
import Programs from "../Pages/Programs";

const allRoutes = [
  { path: "/emissions", component: EmissionPage2, exact: true },
  { path: "/emissions/:titre", component: EmissionPage2 },
  { path: "/emissions/:titre/:title_video", component: EmissionPage2 },
  { path: "/tv", component: TVPage, exact: true },
  { path: "/tv/:titre", component: TVPage, expect: true },
  { path: "/tv/:slug:/:video_slug", component: TVPage, expect: true },
  { path: "/guide", component: Guide, exact: true },
  { path: "/programs", component: Programs, exact: true },
  { path: "/actualite", component: Actualite, exact: true },
  { path: "/actualite/:slug", component: Actualite, exact: true },
  { path: "/actualite/detail/:category_slug/:slug", component: NewsDetail },
  { path: "/video", component: SliderPage, exact: true },
  { path: "/video/:titre", component: SliderPage, exact: true },
  { path: "/dernierevideo/:slug", component: LiveVideoDetail, exact: true },
  { path: "/radios", component: RadioPage, exact: true },
  { path: "/radios/:type/:title", component: Radio, exact: true },
  { path: "/", component: Acceuil, exact: true },
  { path: "", component: PageNotFound },
];

export default allRoutes;
