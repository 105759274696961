import React, { Component } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import Cookies from "universal-cookie";
import { base_url, wpJsonUrl } from "../Model/config";
import "../Header/css/app.css";
import "../Header/css/theme.css";
import "../Header/css/custom.css";
import "../Header/css/font-awesome.min.css";
import "../Header/layerslider/css/layerslider.css";
import "../Header/css/owl.carousel.min.css";
import "../Header/css/owl.theme.default.min.css";
import "../Header/css/responsive.css";
import "../Header/css/custom-responsive.css";
import "./acc.css";
// Carousel
import { Container, Grid } from "@material-ui/core";
// Components
import SpotlightView from "../Components/SpotlightView";
import FeaturedPart from "../Components/FeaturedPart";
import moment from "moment";
import "moment/locale/fr";
import FlashInfo from "../Components/FlashInfo";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import SelectionRadio from "../Components/SelectionRadio";
import PodcastRadio from "../Components/PodcastRadio";
import ReplayTV from "../Components/ReplayTV";
import SelectionTV from "../Components/SelectionTv";
import AdvertiseImage from "../Components/AdvertiseImage";
import { bannerCode1 } from "../Model/config";
import Loading from "../Components/Loading";
moment.locale("fr");

const cookies = new Cookies();

class Acceuil extends Component {
  state = {
    alaunesliders: [],
    alaunes: [],
    list_alaunes: [],
    guides: [],
    matin: [],
    jtrts: [],
    tab: [],
    featuredData: [],
    info: [],
    allitemsfeed: [],
    xibaars: [],
    videovingth: [],
    videotreize: [],
    sliders: [],
    tvLists: [],
    min: 0,
    min1: 1,
    max: 3,
    max4: 13,
    max5: 8,
    open: false,
    setOpen: false,
    video_url: "",
    srctv: "",
    titre_play: "",
    desc_play: "",
    url_play: "",
    titre_silver: "",
    title_vingth: "",
    title_treize: "",
    title_xibaars: "",
    topBannerUrl: "",
  };

  async componentDidMount() {
    const topBannerUrl = await getBannerUrl(bannerCode1);
    this.setState({ topBannerUrl });

    const infos = await getData(
      `${wpJsonUrl}/posts/?per_page=10&_embed=wp:term`
    );
    if (infos && infos.length > 0) {
      this.setState({ info: infos });
    }

    axios
      .get(`${wpJsonUrl}/categories/?slug=a-la-une`)
      .then((featuredRes) => {
        const url = featuredRes.data[0]["_links"]["wp:post_type"][0]["href"];
        this.getFeaturedData(url + "&_embed");
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios.get(base_url + "listLiveTV/larts/json").then((res) => {
      const tvs = res.data;
      const url = res.data.allitems[0].feed_url;
      const title = res.data.allitems[0].title;
      const desc = res.data.allitems[0].desc;
      this.setState({
        tvs,
        title,
        desc,
        week_date: Date().slice(0, 3),
        tvLists: tvs.allitems,
      });

      axios.get(url).then((res) => {
        const srctv = res.data.direct_url;
        this.setState({ srctv });
      });
    });

    axios.get(base_url + "alauneByGroup/larts/json").then((res) => {
      const list_alaunes = res.data;
      const alaune_url = res.data.allitems[0].feed_url;
      const titre_play = res.data.allitems[0].title;
      const video_url = res.data.allitems[0].logo;
      const desc_play = res.data.allitems[0].desc;
      this.setState({
        list_alaunes,
        titre_play,
        video_url,
        alaune_url,
        desc_play,
      });
    });
  }

  getFeaturedData = async (url) => {
    const newsList = await getData(url);
    if (newsList.length > 0) {
      this.setState({ featuredData: newsList });
    }
  };

  handleClick = (title, date, desc, lien, categorie, e) => {
    setTimeout(cookies.set("titre", title, { path: "/" }), 1000);
    setTimeout(cookies.set("datepub", date, { path: "/" }), 1000);
    setTimeout(cookies.set("desc", desc.toString(), { path: "/" }), 1000);
    setTimeout(cookies.set("lien", lien.toString(), { path: "/" }), 1000);
    setTimeout(
      cookies.set("categorie", categorie.toString(), { path: "/" }),
      1000
    );
  };

  getChannelImg = (id) => {
    const res = this.state.tvLists.find((i) => i.id === id);
    if (res) {
      return res.logo_url;
    }
    return "";
  };

  handleSelectionChange = (val) => {
    return;
  };

  render() {
    const { featuredData, info, topBannerUrl } = this.state;
    return (
      <div style={{ marginBottom: "5rem" }}>
        <FlashInfo />
        {topBannerUrl ? (
          <AdvertiseImage imageUrl={topBannerUrl} />
        ) : (
          <Loading />
        )}
        <section className="spotlight">
          <Container>
            <Grid container className="flash-title">
              <Grid item xs={12} md={9}>
                <div className="title-border">
                  <h4>A LA UNE</h4>
                </div>
                {featuredData.length > 0 ? (
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <div className="anwp-pg-wrap">
                        <div className="d-flex anwp-row flex-wrap no-gutters anwp-pg-hero-block anwp-pg-posts-wrapper anwp-pg-gutter--thin">
                          <div className="anwp-col-sm-12 anwp-pg-height-2x anwp-pg-hero-main-block-wrapper">
                            <SpotlightView data={featuredData[0]} type={1} />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="anwp-pg-wrap">
                        <div className="d-flex anwp-row flex-wrap no-gutters anwp-pg-hero-block anwp-pg-posts-wrapper anwp-pg-gutter--thin">
                          {featuredData.length > 1 &&
                            featuredData.slice(1, 5).map((item, index) => {
                              if (item) {
                                return (
                                  <div
                                    key={index}
                                    className={`featured-thumbnail anwp-pg-hero-secondary-block-wrapper ${
                                      index % 2 === 1 ? "d-featured-none" : ""
                                    }`}>
                                    <SpotlightView data={item} type={0} />
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div className="col-md-10 text-center">
                    <Loader
                      type="ThreeDots"
                      color="#971B25"
                      height={10}
                      width={60}
                      visible={true}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <FeaturedPart
                  featuredData={info}
                  startIndex={6}
                  endIndex={10}
                  hasPlus={true}
                />
              </Grid>
            </Grid>
          </Container>
        </section>


        {/* Replay TV part */}
        <ReplayTV title={"Revoir TV"} />

        {/* Sélection TV part */}
        <SelectionTV
          title={"Sélection TV"}
          url={`${base_url}listChannelsbygroup/larts/json`}
          isSelection={true}
          onSelectionChange={this.handleSelectionChange}
        />

        {/* Selection Radio */}
        <SelectionRadio
          url={base_url + "listshows/larts/json"}
          title="Sélection Radio"
        />

        {/* Podcasts Radios */}
        <PodcastRadio
          url={base_url + "latestaoditemsByGroupe/larts/json"}
          title="Podcasts Radios"
        />
      </div>
    );
  }
}

export default Acceuil;
