import React from "react";
import { Container, Grid } from "@material-ui/core";

function FooterCopyright() {
  return (
    <footer className="home-footer-copyright">
      <Container>
        <Grid
          container
          className="global-padding color-black"
          justifyContent="center">
          © 2021 RTS. Tous droits réservés
        </Grid>
      </Container>
    </footer>
  );
}

export default FooterCopyright;
