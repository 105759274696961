import React from "react";
import { Button, Box } from "@material-ui/core";
import { getChannelColor } from "../Utils/CommonFunctions";
import rts1 from "../Assets/images/rts1.png";
import rts2 from "../Assets/images/rts2.png";
import rts3 from "../Assets/images/rts3.png";
import rts4 from "../Assets/images/rts4.png";
import rts5 from "../Assets/images/rts5.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    margin: "30px 0",
    textAlign: "center",
  },
  button: {
    fontSize: "26px",
    border: "1px solid #C7C4C4",
    borderRadius: "15px",
  },
  icon: {
    fontSize: "40px!important",
  },
  image: {
    marginLeft: "0.5rem",
  },
}));

const getImageUrl = (id) => {
  switch (id) {
    case "2":
      return rts2;
    case "3":
      return rts3;
    case "4":
      return rts4;
    case "5":
      return rts5;
    default:
      return rts1;
  }
};

function TvDropDownBtn({ title, num, isOpened, onShowButtonChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Button
        variant="outlined"
        endIcon={
          isOpened ? (
            <KeyboardArrowUpIcon className={classes.icon} />
          ) : (
            <KeyboardArrowDownIcon className={classes.icon} />
          )
        }
        style={{ color: getChannelColor(parseInt(num)) }}
        className={classes.button}
        onClick={onShowButtonChange}>
        {title}
        <Box
          component={"img"}
          src={getImageUrl(num)}
          alt="small-logo"
          className={classes.image}
          height={"35px"}></Box>
      </Button>
    </Box>
  );
}

export default TvDropDownBtn;
