import React from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "./index.css";

const RadioChannelsSwiper = ({ radios, channel }) => {
  return (
    <section className="selection-radio section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12}>
            <BlockTitle title={"RADIOS FM"} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              rewind={true}
              navigation={true}
              breakpoints={{
                1300: {
                  slidesPerView: 6,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 2,
                },
                300: {
                  width: 300,
                  slidesPerView: 2,
                },
              }}>
              {radios.length > 0 ? (
                radios.map((radio, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="tv-swiper-wrapper">
                        <div
                          className={`radio-swiper-sub-wrapper ${
                            radio.slug === channel ? "tv-swiper-active" : ""
                          }`}>
                          <Link to={"/radios/live/" + radio.slug}>
                            <img
                              className="radio-swiper-img"
                              src={radio.sd_logo}
                              alt={radio.title}
                              title={radio.title}
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <Loading />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default RadioChannelsSwiper;
