import React, { useState, useEffect } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import FlashInfo from "../Components/FlashInfo";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import { wpJsonUrl } from "../Model/config";
import FeaturedPart from "../Components/FeaturedPart";
import Loading from "../Components/Loading";
import NewsWrapper from "../Components/NewsWrapper";
import { urlSlug } from "../Utils/CommonFunctions";
import { withRouter, useParams } from "react-router-dom";
import TwitterPart from "../Components/TwitterPart";
import AdvertiseImage from "../Components/AdvertiseImage";
import { bannerCode2 } from "../Model/config";
import "./index.css";

const News = () => {
  const [featuredInfo, setFeaturedInfo] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryLength, setCategoryLength] = useState(3);
  const [bannerUrl, setBannerUrl] = useState("");

  const slug = urlSlug(1, "actualite");

  useEffect(() => {
    const getCategories = async () => {
      const url = !slug
        ? `${wpJsonUrl}/categories?per_page=50&parent=0&orderby=count&order=desc`
        : `${wpJsonUrl}/categories/?slug=${slug}`;
      const res = await getData(url);
      const infos = await getData(
        `${wpJsonUrl}/posts/?per_page=10&_embed=wp:term`
      );
      if (res && res.length > 0) {
        setCategories(res);
      }
      if (infos && infos.length > 0) {
        setFeaturedInfo(infos);
      }
    };
    getCategories();
    getBanner();
  }, [slug]);

  const getBanner = async () => {
    const url = await getBannerUrl(bannerCode2);
    if (url) setBannerUrl(url);
  };

  const handleCharger = () => {
    let len = categoryLength;
    setCategoryLength(len + 1);
  };

  return (
    <>
      <FlashInfo />
      {/* Advert Part */}
      <section className="selection-tv section-mt">
        <Container>
          <Grid container className="flash-title">
            {bannerUrl ? (
              <AdvertiseImage
                imageUrl={bannerUrl}
                sx={{ width: "70%", m: "auto" }}
              />
            ) : (
              <Loading />
            )}
          </Grid>
        </Container>
      </section>

      <section className="replay_tv section-mt">
        <Container>
          <Grid container className="flash-title">
            <Grid item xs={12} md={9}>
              <Grid container>
                {categories.length > 0 ? (
                  <NewsWrapper
                    categories={categories}
                    categoryLength={categoryLength}
                    hasSlug={!!slug}
                  />
                ) : (
                  <Loading />
                )}
                {!slug ? (
                  <Grid item xs={12} className="text-center charger">
                    <Button onClick={() => handleCharger()}>Charger +</Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              {featuredInfo.length > 0 && (
                <FeaturedPart
                  featuredData={featuredInfo}
                  startIndex={0}
                  endIndex={6}
                  hasPlus={false}
                />
              )}
              <TwitterPart />
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default withRouter(News);
