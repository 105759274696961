import React from 'react'
import { Container } from '@material-ui/core'
import './index.css'

export default function PageNotFound() {
  return (
    <div>
        <Container className='page-not-found'>
            <p>
                404
            </p>
        </Container>
    </div>
  )
}
