import React from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "./index.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const TvChannelsSwiper = ({ tvs, channel, onUrlChange }) => {
  return (
    <section className="selection-radio section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12}>
            <BlockTitle title={"Télévisions"} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              rewind={true}
              navigation={true}
              breakpoints={{
                1300: {
                  slidesPerView: 5,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 2,
                },
                300: {
                  width: 300,
                  slidesPerView: 2,
                },
              }}>
              {tvs.length > 0 ? (
                tvs.map((tv, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="tv-swiper-wrapper">
                        <Link
                          to={"/tv/" + tv.slug}
                          onClick={() => onUrlChange("/tv/" + tv.slug)}>
                          <div
                            className={`tv-swiper-sub-wrapper ${
                              tv.id === channel.id ? "tv-swiper-active" : ""
                            }`}>
                            <img
                              className="tv-swiper-img"
                              src={tv.logo_url}
                              alt={tv.title}
                              title={tv.title}
                            />
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <Loading />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default TvChannelsSwiper;
