import React, { Component } from "react";
import "./guide.css";
// import "./guide1.css";
import { Glyphicon, Image } from "react-bootstrap";
import calendar from "./calendar.png";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { base_url } from "../Model/config";
import point from "./point.png";
import matin1 from "./matin1.png";
import soir from "./soir1.png";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ReactGA from "react-ga";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
} from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import BackToTop from "react-back-top";
registerLocale("fr", fr);
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "6px 16px",
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));
class Guide extends Component {
    state = {
        allguides: [],
        matins: [],
        jour: "",
    };

    async componentDidMount() {
        ReactGA.initialize('UA-100085590-1');
        ReactGA.pageview(window.location.pathname+window.location.search)
        window.location.href = "#";
        axios.get(base_url + "guidetvchannels/larts/wed/demo.stream/json").then((res) => {
            const allguides = res.data;
            this.setState({
                allguides,
                startDate: new Date(),
                week_date: Date().slice(0, 3),
                jour: Date().slice(0, 3),
            });
        });
    }
    state = {

    };
    handleChange = (date) => {
        this.setState({
            startDate: date,
        });
    };
    handleClick = (datedt, e) => {
        e.preventDefault();
        this.setState({ week_date: datedt });
        this.getDate(this.state.week_date);


        // var btnContainer = document.getElementById("journav");
        var btns = document.getElementsByClassName("navbar");

        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("current");
                if (current.length > 0) {

                    current[0].className = current[0].className.replace("current", "");
                }
                this.className += " current";
            });
        }
    };
    getDate(strdate) {
        switch (strdate) {
            case "Mon":
                return "Lundi";
                break;
            case "Tue":
                return "Mardi";
                break;
            case "Wed":
                return "Mercredi";
                break;
            case "Thu":
                return "Jeudi";
                break;
            case "Fri":
                return "Vendredi";
                break;
            case "Sat":
                return "Samedi";
                break;

            default:
                return "Dimanche";
                break;
        }
    }
    render() {

        const config = {
            text: "Haut",
            topDistance: 10,
            timing: "easeIn",
        };
        const date = this.state.allguides
            ? this.state.allguides.allitems.matin
            : "";
        const soirs = this.state.allguides
            ? this.state.allguides.allitems.soir
            : "";
        return (
            <div>
                <BackToTop
                    color="#fff"
                    icon="fa fa-arrow-up"
                    shape="round"
                    background="#c50705"
                    //hover={bg}
                    config
                />
                <div style={{ display: 'none' }} id="today">
                    {this.getDate(this.state.week_date)}
                </div>
                <section class="latest-items">
                    <div className="row">

                        <div className="Schedule-header">
                            <div className="row">
                                {/* <div class="large-12 columns">
                                <?php setlocale(LC_TIME, 'french'); ?>
                                <h1><?= strftime('%A %d %B %Y') ?></h1>
                            </div> */}
                            </div><div className="row">
                                <ul className="schedule-days">
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Mon", e)} className="navbar">Lundi</a></h3>
                                    </li>
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Tue", e)} className="navbar">Mardi</a></h3>
                                    </li>
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Wed", e)} className="navbar">Mercredi</a></h3>
                                    </li>
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Thu", e)} className="navbar">Jeudi</a></h3>
                                    </li>
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Fri", e)} className="navbar">Vendredi</a></h3>
                                    </li>
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Sat", e)} className="navbar">Samedi</a></h3>
                                    </li>
                                    <li>
                                        <h3><a href="" onClick={(e) => this.handleClick("Sun", e)} className="navbar">Dimanche</a></h3>
                                    </li>
                                </ul>
                            </div><table>
                                <thead>
                                    <tr>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <br />
                        <div className="Schedule-content">
                            <div className="schedule-content-programme">
                                {/*?php setlocale(LC_TIME, "fr_FR"); ?*/}
                                <h3><span className="programme">Programme du {this.getDate(this.state.week_date)}</span></h3>
                            </div>

                            <VerticalTimeline className={"verline"}>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot id="matinbg">
                                            {/* id="matinbg" */}
                                            <Image src={matin1} />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent />
                                </TimelineItem>
                                {date ? (
                                    date.map((matin, i) => {
                                        if (matin.daysInWeek.includes(this.state.week_date)) {
                                            return (
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{
                                                        background: "#c50705",
                                                        color: "#fff",
                                                    }}
                                                    contentArrowStyle={{
                                                        borderRight: "7px solid  #c50705",
                                                    }}
                                                    iconStyle={{
                                                        background: "#000",
                                                        color: "#fff",
                                                    }}
                                                //lineColor={"#971B25"}
                                                >
                                                    <h3 className="vertical-timeline-element-title" style={{ color: "white" }}>
                                                        {matin.startTime} - {matin.endTime}
                                                    </h3>
                                                    <h4 className="vertical-timeline-element-subtitle" style={{ color: "white" }}>
                                                        {matin.title}
                                                    </h4>
                                                    <p>Présenté par {matin.presentateurs}</p>
                                                </VerticalTimelineElement>
                                            );
                                        } else {
                                            return (
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{
                                                        background: "#c50705",
                                                        color: "#fff",
                                                    }}
                                                    contentArrowStyle={{
                                                        borderRight: "7px solid  #c50705",
                                                    }}
                                                    iconStyle={{
                                                        background: "#000",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    <h3 className="vertical-timeline-element-title" style={{ color: "white" }}>
                                                        Aucun programme défini
                    </h3>
                                                </VerticalTimelineElement>
                                            );
                                        }
                                    })
                                ) : (
                                    <div className="col-md-10 text-center">
                                        <Loader
                                            type="ThreeDots"
                                            color="#87BB24"
                                            height={10}
                                            width={60}
                                            visible={true}
                                        />
                                    </div>
                                )}
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot id="matinbg">
                                            <Image src={soir} />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent />
                                </TimelineItem>
                                {soirs ? (
                                    soirs.map((soir, i) => {
                                        if (soir.daysInWeek.includes(this.state.week_date)) {
                                            return (
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{
                                                        background: "#c50705",
                                                        color: "#fff",
                                                    }}
                                                    contentArrowStyle={{
                                                        borderRight: "7px solid  #c50705",
                                                    }}
                                                    iconStyle={{
                                                        background: "#000",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    <h3 className="vertical-timeline-element-title" style={{ color: "white" }}>
                                                        {soir.startTime} - {soir.endTime}
                                                    </h3>
                                                    <h4 className="vertical-timeline-element-subtitle" style={{ color: "white" }}>
                                                        {soir.title}
                                                    </h4>
                                                    <p style={{ color: "white" }}>présenté par {soir.presentateurs}</p>
                                                </VerticalTimelineElement>
                                            );

                                        } else {
                                        }
                                    })
                                ) : (
                                    <div className="col-md-10 text-center">
                                        <Loader
                                            type="ThreeDots"
                                            color="#87BB24"
                                            height={10}
                                            width={60}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </VerticalTimeline>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Guide;
