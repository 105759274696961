import React from "react";
import { Container, Grid } from "@material-ui/core";
import BlockTitle from "./BlockTitle";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

function LinkedChannelList({ channelData, tvSlug, onPathChange }) {
  return (
    <section
      className="video-inner-section selection-tv section-mt"
      key={channelData && channelData.title}>
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12} className="grey-back">
            <BlockTitle title={channelData.title} />
            <div className="video-sub my-1">
              <div className="row">
                {channelData.subitems.data &&
                channelData.subitems.data.length > 0 ? (
                  channelData.subitems.data.map((item, i) => (
                    <div
                      className="large-3 medium-3 columns"
                      style={{ marginBottom: "1.5rem" }}
                      key={i}>
                      <Link
                        to={"/emissions/" + item.slug}
                        onClick={onPathChange}>
                        <div className="video-thumb">
                          <img src={item.logo_url} alt="" title="" />
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <LoadingSpinner status={2} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default LinkedChannelList;
