import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import Cookies from "universal-cookie";
import { base_url } from "../Model/config";
import { base_url1 } from "../Model/config";
import ClapprWrapper from "../Utils/clapprreplay";
import ReactGA from "react-ga";
import "../Header/css/app.css";
import "../Header/css/theme.css";
import "../Header/css/custom.css";
import "../Header/css/font-awesome.min.css";
import "../Header/layerslider/css/layerslider.css";
import "../Header/css/owl.carousel.min.css";
import "../Header/css/owl.theme.default.min.css";
import "../Header/css/responsive.css";
import "../Header/css/custom-responsive.css";
import rts1hdsmall from "../Header/images/rts/rts1hdsmall.png";
import rts2hdsmall from "../Header/images/rts/rts2hdsmall.png";
import rts3hdsmall from "../Header/images/rts/rts3hdsmall.png";
import rts4hdsmall from "../Header/images/rts/rts4hdsmall.png";
import rsismall from "../Header/images/rts/rsismall.png";
import logosmall from "../Header/images/logo-small.png";
import rtslogo from "../Header/images/rts-logo.png";
import playbutton from "../Header/images/play-button.png";
import programmain from "../Header/images/program-main.jpg";
import progressbar from "../Header/images/progressbar.jpg";
import iosappstore from "../Header/images/ios-app-store.png";
import googleplaystore from "../Header/images/google-play-store.png";
import programmainv2 from "../Header/images/program-main-v2.jpg";
import jellbii from "../Header/images/jell-bii.jpg";
const cookies = new Cookies();

class SliderPage extends Component {
  state = {
    emissions: [],
    oneemissions: [],
    min: 0,
    max: 3,
    max1: 6,
    url: "",
    src: "",
    title: "",
    desc: "",
  };
  componentDidMount() {
    ReactGA.initialize("UA-100085590-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    var url = window.location.pathname;
    //window.addEventListener('scroll', this.handleScroll);
    url = url.split("/");
    var lien_emission;
    var userId = url[url.length - 1];
    var newstr3 = decodeURI(userId);
    var newstr4 = newstr3.replaceAll("_", " ");
    //alert(newstr4)

    /*const desc = cookies.get("descdernier");
        const urlvideo = cookies.get("urldernier");
        const title = cookies.get("titredernier");*/

    /* axios.get(urlvideo).then((res) => {
            const src = res.data.video_url;
            this.setState({ src,desc,title });
        });*/

    axios.get(base_url + "alaunesliders/larts/json").then((res) => {
      const oneemissions = res.data;
      // const scr = res.data.allitems[0].feed_url;
      // const title = res.data.allitems[0].title;
      // const desc = res.data.allitems[0].desc;

      for (let i = 0; i < oneemissions.allitems.length; i++) {
        if (oneemissions.allitems[i].title.trim() == newstr4) {
          //alert(oneemissions.allitems[i].feed_url.trim());
          if (oneemissions.allitems[i].video_url == "") {
            axios.get(oneemissions.allitems[i].feed_url).then((res) => {
              const urlvideo = res.data["video_url"];
              //alert(urlvideo)
              this.setState({
                src: urlvideo,
                title: oneemissions.allitems[i].title,
                desc: oneemissions.allitems[i].desc,
              });
            });
          } else {
            this.setState({
              src: oneemissions.allitems[i].video_url,
              title: oneemissions.allitems[i].title,
              desc: oneemissions.allitems[i].desc,
            });
          }
        } else {
          const error_list = "La vidéo n'existe plus";
          this.setState({
            error_list,
          });
        }
      }
      this.setState({ oneemissions });
    });
  }
  handleClick = (url, titre, description, e) => {
    //e.preventDefault();
    // window.location = '/home/';
    ReactGA.initialize("UA-100085590-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    axios.get(url).then((res) => {
      const src = res.data["video_url"];
      this.setState({ src });
      this.setState({ src, title: titre, desc: description });
    });
  };
  render() {
    const emissions = this.state.emissions.allitems;
    const oneemissions = this.state.oneemissions.allitems;
    return (
      <div>
        <section className="video-inner-section">
          <div className="video-title">
            <div className="row">
              <div className="large-12 columns">
                <h2>REPLAY TV</h2>
              </div>
            </div>
          </div>
          <div className="video-main">
            <div className="row">
              <div className="large-7 medium-12 columns">
                {/* <a href="//live.rts.sn/home/player/17228"> */}
                <div className="video-main-inner">
                  <ClapprWrapper src={this.state.src} />
                </div>
                {/* </a> */}
              </div>
              <div className="large-5 medium-12 columns">
                <div className="video-info">
                  <h4>{this.state.title}</h4>
                  <p>{this.state.desc}</p>
                  <div className="video-meta-info">
                    <div className="duration">
                      <p>
                        Duree : <b> 2:33</b>{" "}
                      </p>
                    </div>
                    <div className="video-author">
                      <h3>11 juillet 2019</h3>
                      <br />
                      <p></p>
                    </div>
                    <div className="video-social">
                      <div className="socialLinks">
                        <a href="http://live.rts.sn/emissions#">
                          <i className="fa fa-facebook-f" />
                        </a>
                        <a href="http://live.rts.sn/emissions#">
                          <i className="fa fa-twitter" />
                        </a>
                        <a href="http://live.rts.sn/emissions#">
                          <i className="fa fa-google-plus" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video-inner-section">
          <div className="video-title">
            <div className="row">
              <div className="large-12 columns">
                <h2>LES VIDEOS DE LA MEME EMISSION</h2>
              </div>
            </div>
          </div>
          <div className="video-sub">
            <div className="row">
              {oneemissions ? (
                oneemissions.map((emission, i) => (
                  <div className="large-3 medium-3 columns">
                    <Link
                      // href="#"
                      onClick={(e) =>
                        this.handleClick(
                          emission.feed_url,
                          emission.title,
                          emission.desc,
                          e
                        )
                      }
                      to={"/video/" + emission.title.replaceAll(" ", "_")}>
                      <div className="video-duration">
                        <span>{emission.time}</span>
                      </div>
                      <div className="video-thumb">
                        <img
                          src={emission.logo}
                          alt={emission.title}
                          title={emission.title}
                        />
                      </div>
                      <div className="play-btn">
                        <img
                          src={playbutton}
                          alt={emission.title}
                          title={emission.title}
                        />
                      </div>
                      <p>{emission.title}</p>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="col-md-10 text-center">
                  (
                  <Loader
                    type="ThreeDots"
                    color="#971B25"
                    height={10}
                    width={60}
                    visible={true}
                  />
                  )
                </div>
              )}
            </div>
          </div>
          <div className="more-btn-wrap">
            <div className="row">
              <div className="large-12 columns">
                <h2 className="more-btn">
                  <a href="javascript:void(0)">
                    {" "}
                    <i className="fa fa-plus" /> DE VIDÉOS{" "}
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default SliderPage;
