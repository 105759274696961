import React, { Component } from "react";
import Clappr from "clappr"; //
import LevelSelector from "level-selector";
import P2PEngine from "cdnbye/dist/hlsjs-p2p-engine.min.js";
import CDNByeClapprPlugin from "cdnbye/dist/clappr-plugin.min.js";

window.P2PEngine = P2PEngine;
class ClapprWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.playerInstance = new Clappr.Player({
      autoPlay: true,
      mute: false,
      preload: "auto",
      parentId: "#player",
      width: "100%",
      debug: false,
      //500px
      height: "439px",
      plugins: [CDNByeClapprPlugin, LevelSelector],
      levelSelectorConfig: {
        title: "Quality",
        labels: {
          2: "HD",
          1: "SD",
          0: "3G",
        },
        labelCallback: function (playbackLevel, customLabel) {
          return customLabel + playbackLevel.level.height + "p"; // High 720p
        },
      },
      playback: {
        hlsjsConfig: {
          maxBufferSize: 0,
          maxBufferLength: 10,
          liveSyncDurationCount: 10,
          p2pConfig: {
            live: true,
          },
        },
      },
    });
    this.nodeRef = React.createRef();
  }

  componentDidMount() {
    this.playerInstance.attachTo(this.nodeRef.current);
    this.loadSource(this.props.src);
  }

  componentWillUnmount() {
    this.playerInstance.destroy();
  }

  shouldComponentUpdate(nextProps, _) {
    if (nextProps.src !== this.props.src) {
      this.loadSource(nextProps.src);
    }
    return false;
  }

  loadSource(src) {
    this.playerInstance.load(src);
  }

  render() {
    return <div ref={this.nodeRef} className="player-wrapper" />;
  }
}

export default ClapprWrapper;
