/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { base_url } from "../Model/config";
import ClapprWrappervod from "../Utils/playervod";
import playbutton from "../Header/images/play-button.png";
import { Divider, Grid, Container } from "@material-ui/core";
import Loading from "../Components/Loading";
import BlockTitle from "../Components/BlockTitle";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import { bannerCode2 } from "../Model/config";
import SelectionTV from "../Components/SelectionTv";
import { getParameterByName } from "../Utils/CommonFunctions";
import AdvertiseImage from "../Components/AdvertiseImage";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Link } from "react-router-dom";

import { urlSlug } from "../Utils/CommonFunctions";

const LiveVideoDetail = () => {
  const slug = urlSlug(1);
  const [videoDetail, setVideoDetail] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [selectionTVUrl, setSelectionTVUrl] = useState("");
  const [relatedTVList, setRelatedTVList] = useState([]);
  const [bannerUrl, setBannerUrl] = useState("");

  useEffect(() => {
    getInitialValues();
    getAdvertUrl();
  }, [slug]);

  const getAdvertUrl = async () => {
    const bannerUrl = await getBannerUrl(bannerCode2);
    setBannerUrl(bannerUrl);
  };

  const getInitialValues = async () => {
    const videoData = await getData(
      `${base_url}/details/video/${slug}/larts/json`
    );

    if (videoData.details) {
      setVideoDetail(videoData.details);
      const { feed_url, relatedItems, type, video_url } = videoData.details;

      if (type === "youtube") {
        setVideoUrl(video_url);
      } else {
        const feedData = await getData(feed_url);

        if (feedData && feedData.web_url) {
          setVideoUrl(feedData.web_url);
        } else {
          setVideoUrl("No Data");
        }
      }

      if (relatedItems) {
        const relatedTVs = await getData(relatedItems);

        if (relatedTVs.allitems && relatedTVs.allitems.length > 0) {
          setRelatedTVList(relatedTVs.allitems);
        }
      }
    }

    if (videoData.links) {
      const { listChannelsByChaine } = videoData.links;

      if (listChannelsByChaine) {
        setSelectionTVUrl(listChannelsByChaine);
      }
    }
  };

  const makeYoutubeUrl = (src) => {
    const id = getParameterByName("v", src);
    return `https://www.youtube.com/embed/${id}?autoplay=1&mute=0`;
  };

  return (
    <div style={{ marginBottom: "5rem" }}>
      <section className="selection-radio section-mt">
        <Container>
          <Grid container className="flash-title">
            {videoDetail && videoUrl && videoUrl !== "No Data" ? (
              <Grid item xs={12}>
                <BlockTitle title={`Replay TV`} />
                <div className="video-main my-1">
                  <div className="row">
                    <div className="large-7 medium-12 columns">
                      <div className="video-main-inner">
                        {videoUrl.includes("youtube.com") ? (
                          <iframe
                            width="840"
                            height="480"
                            src={makeYoutubeUrl(videoUrl)}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen></iframe>
                        ) : (
                          <ClapprWrappervod src={videoUrl} />
                        )}
                      </div>
                    </div>

                    <div className="large-5 medium-12 columns">
                      <div className="video-info">
                        <div className="tv-list-title">
                          <span className="tv-list-title-content">
                            {videoDetail.title}
                          </span>
                        </div>
                        <Divider className="tv-list-title-divider" />
                        <p className="tv-list-desc">{videoDetail.desc}</p>
                        <div className="tv-social-link-wrapper text-center">
                          <div className="tv-social-desc">
                            <h3>Suivez-nous sur : </h3>
                          </div>
                          <div className="tv-social-links">
                            <div className="socialLinks">
                              <a
                                href="//www.facebook.com/RTS1.Senegal/"
                                target="_blank"
                                rel="noreferrer">
                                <i className="fa fa-facebook-f" />
                              </a>
                              <a
                                href="https://www.instagram.com/rts1_senegal/"
                                target="_blank"
                                rel="noreferrer">
                                <i className="fa fa-instagram" />
                              </a>
                              <a
                                href="//twitter.com/rts1_senegal?lang=fr"
                                target="_blank"
                                rel="noreferrer">
                                <i className="fa fa-twitter" />
                              </a>
                              <a href="#">
                                <i className="fa fa-whatsapp" />
                              </a>
                              <a
                                href="https://www.youtube.com/c/RTS2S%C3%A9n%C3%A9galofficiel"
                                target="_blank"
                                rel="noreferrer">
                                <i className="fa fa-youtube" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ) : videoUrl === "No Data" ? (
              <LoadingSpinner status={2} />
            ) : (
              <LoadingSpinner status={3} />
            )}
          </Grid>
        </Container>
      </section>

      {/* Advert Part */}
      <section className="selection-tv section-mt">
        <Container>
          <Grid container className="flash-title">
            {bannerUrl ? (
              <AdvertiseImage
                imageUrl={bannerUrl}
                sx={{ width: "70%", m: "auto" }}
              />
            ) : (
              <Loading />
            )}
          </Grid>
        </Container>
      </section>

      <section className="video-inner-section selection-tv section-mt">
        <Container>
          <Grid container className="flash-title">
            {relatedTVList.length > 0 ? (
              <Grid item xs={12} className="grey-back">
                <BlockTitle title={`DERNIERES EPISODES`} />
                <div className="video-sub my-1">
                  <div className="row">
                    {relatedTVList ? (
                      relatedTVList.map((emission, i) => (
                        <div className="large-3 medium-3 columns" key={i}>
                          <Link to={"/dernierevideo/" + emission.slug}>
                            <div className="video-duration">
                              <span>{emission.time}</span>
                            </div>
                            <div className="video-thumb">
                              <img
                                src={emission.logo}
                                alt={emission.title}
                                title={emission.title}
                              />
                            </div>
                            <div className="play-btn">
                              <img
                                src={playbutton}
                                alt={emission.title}
                                title={emission.title}
                              />
                            </div>
                            <p>{emission.title}</p>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <div className="col-md-10 text-center">
                        (
                        <Loader
                          type="ThreeDots"
                          color="#971B25"
                          height={10}
                          width={60}
                          visible={true}
                        />
                        )
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            ) : (
              <Loading />
            )}
          </Grid>
        </Container>
      </section>

      {/* Selection TV */}
      {selectionTVUrl && (
        <SelectionTV
          title={`Sélection TV`}
          url={selectionTVUrl}
          isSelection={false}
          onSelectionChange={() => {
            return true;
          }}
        />
      )}
    </div>
  );
};

export default LiveVideoDetail;
