import React, { useEffect, useState } from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container, Button } from "@material-ui/core";
import { wpJsonUrl } from "../Model/config";
import { getData } from "../Helpers/apiHelper";
import Loading from "./Loading";
import EmbeddedNewsContents from "./EmbeddedNewsContents";

function EmbeddedNews({ title, slug }) {
  const [categories, setCategories] = useState([]);
  const [lenOfCategory, setLenOfCategory] = useState(1);

  useEffect(() => {
    getCategories();
  }, [slug]);

  const getCategories = async () => {
    const url = `${wpJsonUrl}/categories/?slug=${slug}`;
    const res = await getData(url);
    if (res && res.length > 0) {
      setCategories(res);
    }
  };

  const increaseCategory = () => {
    setLenOfCategory(lenOfCategory + 1);
  };

  return (
    <section className="selection-tv section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12}>
            <BlockTitle title={title} />
            {categories.length > 0 ? (
              categories.slice(0, lenOfCategory).map((category, index) => {
                // if (index < lenOfCategory) {
                  return (
                    <EmbeddedNewsContents
                      key={category.id}
                      categoryUrl={category["_links"]["wp:post_type"][0].href}
                      slug={category.slug}
                    />
                  );
                // }
              })
            ) : (
              <Loading />
            )}
          </Grid>
          {categories.length > 1 && (
            <Grid item xs={12} className="text-center charger">
              <Button onClick={() => increaseCategory()}>Charger +</Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </section>
  );
}

export default EmbeddedNews;
