import React, { useState, useEffect } from "react";
import { Grid, Container, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  leftLogo: {
    textAlign: "center",
    background: "#DBDBDB",
    borderRadius: "10px 0px 0px 10px",
    padding: "30px 5px",
    color: "#000000",
  },
  swiperWrapper: {
    padding: "5px",
    position: "relative",
  },
  swiperBorder: {
    "&::after": {
      position: "absolute",
      content: "''",
      background: "#A8A5A5",
      bottom: "-5px",
      left: "50%",
      height: "1px",
      transform: "translate(-50%, -50%)",
      width: "95%",
      margin: "auto",
    },
  },
  swiperImage: {
    display: "flex",
    justifyContent: "space-evenly",
    // "&::after": {
    //   position: "absolute",
    //   content: "''",
    //   background: "#A8A5A5",
    //   right: "10px",
    //   top: "50%",
    //   height: "95%",
    //   transform: "translate(-50%, -50%)",
    //   width: "1px",
    //   margin: "auto",
    // },
  },
  startTime: {
    color: "#C50705!important",
    fontSize: "17px",
    fontWeight: "normal",
  },
  title: {
    color: "#000!important",
    fontSize: "17px",
    fontWeight: "bold",
  },
  category: {
    color: "#606060!important",
    fontSize: "12px",
  },
  present: {
    color: "#686868!important",
    fontSize: "13px",
    fontWeight: "bold",
  },
}));

function ProgramSwiper({ channelData, type }) {
  const classes = useStyles();
  const [lists, setLists] = useState([]);

  useEffect(() => {
    setLists([]);
    if (channelData.subitems) {
      const { matin, soir, direct } = channelData.subitems;
      switch (type) {
        case 1:
          if (matin.length > 0) {
            setLists(matin);
          }
          break;
        case 2:
          if (soir.length > 0) {
            setLists(soir);
          }
          break;
        case 3:
          if (direct) {
            setLists([direct]);
          }
          break;
        case 4:
          const tomorrowList = matin.concat(soir);
          if (tomorrowList.length > 0) {
            setLists(tomorrowList);
          }
          break;
        default:
          if (matin.length > 0) {
            setLists(matin);
          }
          break;
      }
    }
  }, [type, channelData]);

  return (
    <section className="selection-tv section-mt" key={channelData.id}>
      <Container>
        <Grid container className="" style={{ position: "relative" }}>
          <Grid item xs={2} md={1} className={classes.leftLogo}>
            <Box
              component="img"
              src={channelData.logo}
              alt="logo"
              height={"45px"}></Box>
            <Typography
              variant="subtitle1"
              style={{ color: "#000000", fontWeight: "bold" }}>
              {channelData.titre || ""}
            </Typography>
          </Grid>
          <Grid item xs={10} md={11} className={classes.swiperWrapper}>
            <Box className={classes.swiperBorder}>
              <Swiper
                className={`video-swiper`}
                style={{ position: "relative" }}
                spaceBetween={25}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                // slidesPerView={1}
                rewind={true}
                navigation={true}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    width: 640,
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                }}>
                {lists.length > 0
                  ? lists.map((emission, i) => {
                      return (
                        <SwiperSlide key={i} className={classes.swiperImage}>
                          <Box className="">
                            <Typography className={classes.startTime}>
                              {emission.startTime}
                            </Typography>
                            <Typography className={classes.title}>
                              {emission.title}
                            </Typography>
                            <Typography className={classes.category}>
                              {emission.categoryName &&
                                emission.categoryName.split(" ")[0]}
                            </Typography>
                            <Typography className={classes.present}>
                              {emission.presentateurs}
                            </Typography>
                          </Box>
                          <Box>
                            <Link to={"/emissions/" + emission.slug}>
                              <div className="cs-right">
                                <img
                                  className="program-img"
                                  src={emission.logo}
                                  alt={emission.title}
                                  title={emission.title}
                                />
                              </div>
                            </Link>
                          </Box>
                        </SwiperSlide>
                      );
                    })
                  : ""}
              </Swiper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default ProgramSwiper;
