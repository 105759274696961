import axios from "axios";
import { convertXmlToJson } from "../Utils/CommonFunctions";
import { base_url } from "../Model/config";

export const getData = async (url) => {
  const config = {
    method: "get",
    url,
  };
  return await axios(config)
    .then(async (res) => {
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
};

export const getBannerUrl = async (bannerCode) => {
  const bannerData = await getData(
    `${base_url}details/banner/${bannerCode}/larts/json`
  );
  if (bannerData.details.url) {
    return bannerData.details.url;
  }
  return false;
};

export const getContentsFromXML = async (url, title) => {
  return await axios
    .get(url)
    .then(async (res) => {
      const categories = res.data;
      const feedUrl = categories.allitems.find((cate) => cate.title === title);

      if (feedUrl) {
        return await axios.get(feedUrl.feed_url).then(async (feedRes) => {
          const feedData = feedRes.data;
          if (feedData) {
            const featured = convertXmlToJson(feedData);
            const data = featured.rss.channel[0].item;
            return data;
          }
        });
      }
    })
    .catch((err) => {
      return false;
    });
};
