import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container, Divider, Grid } from "@material-ui/core";
import axios from "axios";
import ReactGA from "react-ga";
import Loader from "react-loader-spinner";
import { base_url } from "../Model/config";
import ClapprWrappervod from "../Utils/playervod";
import "../Header/css/app.css";
import "../Header/css/theme.css";
import "../Header/css/custom.css";
import "../Header/css/font-awesome.min.css";
import "../Header/layerslider/css/layerslider.css";
import "../Header/css/owl.carousel.min.css";
import "../Header/css/owl.theme.default.min.css";
import "../Header/css/responsive.css";
import "../Header/css/custom-responsive.css";
import "./emission.css";
import playbutton from "../Header/images/play-button.png";
import dmedia from "../Header/images/rts/larts.png";
import BlockTitle from "../Components/BlockTitle";
import Loading from "../Components/Loading";
import SelectionTV from "../Components/SelectionTv";
import { withRouter } from "react-router-dom";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import { bannerCode2 } from "../Model/config";
import AdvertiseImage from "../Components/AdvertiseImage";
import { getParameterByName } from "../Utils/CommonFunctions";

class EmissionPage2 extends Component {
  state = {
    emissions: [],
    oneemissions: [],
    channel: null,
    min: 0,
    max: 3,
    max1: 6,
    url: "",
    src: "",
    title: "",
    desc: "",
    offset: 0,
    perPage: 12,
    tableData: [],
    orgtableData: [],
    currentPage: 0,
    title_emission: "",
    bannerUrl: "",
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };
  async componentDidMount() {
    const bannerUrl = await getBannerUrl(bannerCode2);
    this.setState({ bannerUrl });

    ReactGA.initialize("UA-75301343-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    var url = window.location.pathname;
    url = url.split("/");
    //alert(url)
    var lien_emission;
    if (url[url.length - 2] === "emissions") {
      var userId = url[url.length - 1];

      var newstr3 = decodeURI(userId);
      var newstr4 = newstr3;
      // this.setState({newstr4})
      axios.get(base_url + "listChannelsbygroup/larts/json").then((res) => {
        const allitemission = res.data;
        this.setState({ allitemission, newstr4 });
        //console.log(this.state.allitemission.allitems[0].title);
        for (let i = 0; i < this.state.allitemission.allitems.length; i++) {
          // console.log('le lien '+i ,this.state.allitemission.allitems[i].title);
          if (this.state.allitemission.allitems[i].slug === newstr4) {
            this.setState({ channel: this.state.allitemission.allitems[i] });
            lien_emission = this.state.allitemission.allitems[i].feed_url;
            //console.log('le lien ',lien_emission);
            const title_emission = newstr4;
            //alert(title_emission)
            axios.get(lien_emission).then((res) => {
              const oneemissions = res.data;
              const slice = oneemissions.allitems.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );
              this.setState({
                oneemissions,
                title: oneemissions.allitems[0].title,
                desc: oneemissions.allitems[0].desc,
                title_emission,
                pageCount: Math.ceil(
                  oneemissions.allitems.length / this.state.perPage
                ),
                orgtableData: res.data.allitems,
                tableData: slice,
              });
              const urlplay = oneemissions.allitems[0].video_url;

              if (oneemissions.allitems[0].type == "vod") {
                //alert(oneemissions.allitems[0].feed_url)
                axios
                  .get(oneemissions.allitems[0].feed_url)
                  .then((response) => {
                    const src = response.data["video_url"];
                    //alert(response.data['video_url'])
                    this.setState({
                      src,
                    });
                  });
              } else {
                this.setState({
                  src: oneemissions.allitems[0].video_url,
                });
              }
            });
            break;
          }
        }
      });
    } else if (url[url.length - 1] === "emissions") {
      axios.get(base_url + "listChannelsbygroup/larts/json").then((res) => {
        const allitemission = res.data;
        this.setState({ allitemission });
        if (allitemission.allitems.length) {
          this.setState({ channel: this.state.allitemission.allitems[0] });
          lien_emission = this.state.allitemission.allitems[0].feed_url;
          const title_emission = this.state.allitemission.allitems[0].slug;
          axios.get(lien_emission).then((res) => {
            const oneemissions = res.data;
            const slice = oneemissions.allitems.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );
            this.setState({
              oneemissions,
              title: oneemissions.allitems[0].title,
              desc: oneemissions.allitems[0].desc,
              title_emission,
              pageCount: Math.ceil(
                oneemissions.allitems.length / this.state.perPage
              ),
              orgtableData: res.data.allitems,
              tableData: slice,
            });
            const urlplay = oneemissions.allitems[0].video_url;

            if (oneemissions.allitems[0].type == "vod") {
              //alert(oneemissions.allitems[0].feed_url)
              axios.get(oneemissions.allitems[0].feed_url).then((response) => {
                const src = response.data["video_url"];
                //alert(response.data['video_url'])
                this.setState({
                  src,
                });
              });
            } else {
              this.setState({
                src: oneemissions.allitems[0].video_url,
              });
            }
          });
        }
      });
    } else {
      var userId = url[url.length - 2];
      var userId_titre = url[url.length - 1];
      var newstr3_titre = decodeURI(userId_titre);
      var newstr4_titre = newstr3_titre;
      var newstr3 = decodeURI(userId);
      var newstr4 = newstr3;

      // this.setState({newstr4})
      axios.get(base_url + "listChannelsbygroup/larts/json").then((res) => {
        const allitemission = res.data;
        this.setState({ allitemission, newstr4 });
        //console.log(this.state.allitemission.allitems[0].title);
        for (let i = 0; i < this.state.allitemission.allitems.length; i++) {
          // console.log('le lien '+i ,this.state.allitemission.allitems[i].title);
          if (this.state.allitemission.allitems[i].slug === newstr4) {
            lien_emission = this.state.allitemission.allitems[i].feed_url;
            this.setState({ channel: this.state.allitemission.allitems[i] });
            const title_emission = newstr4;
            axios.get(lien_emission).then((res) => {
              const oneemissions = res.data;
              const slice = oneemissions.allitems.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );

              for (let i = 0; i < oneemissions.allitems.length; i++) {
                if (oneemissions.allitems[i].slug === newstr4_titre) {
                  this.setState({
                    title: oneemissions.allitems[i].title,
                    desc: oneemissions.allitems[i].desc,
                  });
                  const urlplay = oneemissions.allitems[i].video_url;

                  if (oneemissions.allitems[i].type == "vod") {
                    //alert(oneemissions.allitems[0].feed_url)
                    axios
                      .get(oneemissions.allitems[i].feed_url)
                      .then((response) => {
                        const src = response.data["video_url"];
                        //alert(response.data['video_url'])
                        this.setState({
                          src,
                        });
                      });
                  } else {
                    this.setState({
                      src: oneemissions.allitems[i].video_url,
                    });
                  }
                }
              }
              this.setState({
                oneemissions,
                title_emission,
                pageCount: Math.ceil(
                  oneemissions.allitems.length / this.state.perPage
                ),
                orgtableData: res.data.allitems,
                tableData: slice,
              });
            });
            break;
          }
        }
      });
    }

    axios.get(base_url + "listChannelsbygroup/larts/json").then((res) => {
      const emissions = res.data;
      this.setState({ emissions });
    });
  }

  handleClick1 = (url, title, e) => {
    //e.preventDefault();
    this.setState({ title_emission: title });
    ReactGA.initialize("UA-100085590-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({
      title_emission: title,
    });
    axios.get(url).then((res) => {
      const oneemissions = res.data;
      const url = res.data.allitems[0].feed_url;
      const title = res.data.allitems[0].title;
      const desc = res.data.allitems[0].desc;

      const slice = oneemissions.allitems.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        oneemissions,
        title,
        desc,
        url,
        pageCount: Math.ceil(oneemissions.allitems.length / this.state.perPage),
        orgtableData: res.data.allitems,
        tableData: slice,
      });
      axios.get(url).then((res) => {
        const src = res.data["video_url"];
        this.setState({ src });
      });
    });
  };

  handleClick = (type, videoUrl, url, titre, description, e) => {
    if (type === "vod") {
      axios.get(url).then((res) => {
        const src = res.data.video_url;
        this.setState({ src, title: titre, desc: description });
      });
      return;
    }
    this.setState({ src: videoUrl, title: titre, desc: description });
  };

  handleSelection = async (val) => {
    const videos = await getData(val.feed_url);
    if (videos) {
      const paginatedVideos = videos.allitems.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      const firstVideo = videos.allitems[0];
      const src = await getData(firstVideo.feed_url);
      this.setState({
        src: src.video_url,
        title: firstVideo.title,
        channel: val,
        desc: firstVideo.desc,
        title_emission: val.title,
        tableData: paginatedVideos,
      });
    }
  };

  makeYoutubeUrl = (src) => {
    const id = getParameterByName("v", src);
    return `https://www.youtube.com/embed/${id}?autoplay=1&mute=0`;
  };

  render() {
    const emissions = this.state.emissions.allitems;
    const oneemissions = this.state.oneemissions.allitems;
    const { channel, bannerUrl } = this.state;
    return (
      <div>
        <section className="selection-radio section-mt">
          <Container>
            <Grid container className="flash-title">
              {channel ? (
                <Grid item xs={12}>
                  <BlockTitle title={`Replay TV | ${channel.chaine_name}`} />
                  <div className="video-main my-1">
                    <div className="row">
                      <div className="large-7 medium-12 columns">
                        <div className="video-main-inner">
                          {this.state.src.includes("youtube.com") ? (
                            <iframe
                              width="840"
                              height="480"
                              src={this.makeYoutubeUrl(this.state.src)}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="autoplay; encrypted-media"
                              allowFullScreen></iframe>
                          ) : (
                            <ClapprWrappervod src={this.state.src} />
                          )}
                        </div>
                      </div>

                      <div className="large-5 medium-12 columns">
                        <div className="video-info">
                          <img
                            src={channel.chaine_logo}
                            alt="channel_logo"
                            className="tv-list-img"
                          />
                          <h4 className="tv-list-channel">
                            {channel.chaine_name || ""}
                          </h4>
                          <div className="tv-list-title">
                            <span className="tv-list-title-content">
                              {this.state.title}
                            </span>
                          </div>
                          <Divider className="tv-list-title-divider" />
                          <p className="tv-list-desc">{this.state.desc}</p>
                          <div className="tv-social-link-wrapper text-center">
                            <div className="tv-social-desc">
                              <h3>Suivez-nous sur : </h3>
                            </div>
                            <div className="tv-social-links">
                              <div className="socialLinks">
                                <a
                                  href="//www.facebook.com/RTS1.Senegal/"
                                  target="_blank"
                                  rel="noreferrer">
                                  <i className="fa fa-facebook-f" />
                                </a>
                                <a
                                  href="https://www.instagram.com/rts1_senegal/"
                                  target="_blank"
                                  rel="noreferrer">
                                  <i className="fa fa-instagram" />
                                </a>
                                <a
                                  href="//twitter.com/rts1_senegal?lang=fr"
                                  target="_blank"
                                  rel="noreferrer">
                                  <i className="fa fa-twitter" />
                                </a>
                                <a href="#">
                                  <i className="fa fa-whatsapp" />
                                </a>
                                <a
                                  href="https://www.youtube.com/c/RTS2S%C3%A9n%C3%A9galofficiel"
                                  target="_blank"
                                  rel="noreferrer">
                                  <i className="fa fa-youtube" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Loading />
              )}
            </Grid>
          </Container>
        </section>

        {/* Advert Part */}
        <section className="selection-tv section-mt">
          <Container>
            <Grid container className="flash-title">
              {bannerUrl ? (
                <AdvertiseImage
                  imageUrl={bannerUrl}
                  sx={{ width: "70%", m: "auto" }}
                />
              ) : (
                <Loading />
              )}
            </Grid>
          </Container>
        </section>

        <section className="video-inner-section selection-tv section-mt">
          <Container>
            <Grid container className="flash-title">
              <Grid item xs={12} className="grey-back">
                <BlockTitle
                  title={`DERNIERES EPISODES | ${
                    (channel && channel.title) || ""
                  }`}
                />
                <div className="video-sub my-1">
                  <div className="row">
                    {this.state.tableData ? (
                      this.state.tableData.map((emission, i) => (
                        <div className="large-3 medium-3 columns" key={i}>
                          <Link
                            //href="#lecteur"
                            to={
                              "/emissions/" +
                              this.state.title_emission +
                              "/" +
                              emission.slug
                            }
                            onClick={(e) =>
                              this.handleClick(
                                emission.type,
                                emission.video_url,
                                emission.feed_url,
                                emission.title,
                                emission.desc,
                                e
                              )
                            }>
                            <div className="video-duration">
                              <span>{emission.time}</span>
                            </div>
                            <div className="video-thumb">
                              <img
                                src={emission.logo}
                                onError={(e) => {
                                  e.target.src = dmedia;
                                  e.target.onError = null;
                                }}
                                alt={emission.title}
                                title={emission.title}
                              />
                            </div>
                            <div className="play-btn">
                              <img
                                src={playbutton}
                                alt={emission.title}
                                title={emission.title}
                              />
                            </div>
                            <p>{emission.title}</p>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <div className="col-md-10 text-center">
                        (
                        <Loader
                          type="ThreeDots"
                          color="#971B25"
                          height={10}
                          width={60}
                          visible={true}
                        />
                        )
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        {/* Selection TV */}
        {channel && (
          <SelectionTV
            title={`Sélection TV | ${channel.chaine_name}`}
            url={`${base_url}listChannelsbygroup/larts/json`}
            isSelection={false}
            onSelectionChange={this.handleSelection}
          />
        )}
      </div>
    );
  }
}

export default withRouter(EmissionPage2);
