import React from "react";
import { Grid } from "@material-ui/core";
import NewsItem from "./NewsItem";
import BlockTitle from "./BlockTitle";

const NewsWrapper = ({ categories, categoryLength, hasSlug }) => {
  return (
    <React.Fragment>
      {categories.map((category, index) => {
        if (index < categoryLength) {
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} className="grey-back news-wrapper">
                <BlockTitle title={category.name} />
                <NewsItem
                  url={category["_links"]["wp:post_type"][0].href}
                  categorySlug={category.slug}
                  hasSlug={hasSlug}
                />
              </Grid>
            </React.Fragment>
          );
        }
      })}
    </React.Fragment>
  );
};

export default NewsWrapper;
