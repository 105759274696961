import { Container, Box, Grid } from "@material-ui/core";
import React from "react";
import Logo from "../Assets/images/rtsLogo.png";
import GrilleTarifaire from "../Assets/images/grille-tarifaire.png";
import { Link } from "react-router-dom";
import "./index.css";

function Footer() {
  return (
    <footer className="home-footer">
      <Container>
        <Grid container className="global-padding">
          <Grid item xs={12} md={4} style={{ marginTop: "1rem" }}>
            <Link to="#">
              <img src={Logo} alt="logo" width="120px" />
            </Link>
            <br />
            <span style={{ fontSize: "14px", color: "#a71d1c", marginBottom: "5px" }}>Le public, notre raison d'être.</span>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            align={{ xs: "left", md: "center" }}
            style={{ marginTop: "1rem" }}>
            <h6
              className="elementor-heading-title elementor-size-default"
              style={{ fontSize: "20px", marginBottom: "20px" }}>
              Télécharger l'application
              <Box sx={{ m: 1 }} />
              <span>
                <a
                  href="https://play.google.com/store/apps/details?id=com.acan.rts"
                  style={{ marginRight: "0.5rem" }}
                  target="_blank"
                  rel="noreferrer">
                  <img
                    src="http://dev.rts.sn/wp-content/uploads/2021/02/google-store.svg"
                    alt="img"
                  />
                </a>
                <a
                  href="https://apps.apple.com/fr/app/rts-lofficiel/id1477314500"
                  style={{ marginLeft: "0.5rem" }}
                  target="_blank"
                  rel="noreferrer">
                  <img
                    src="http://dev.rts.sn/wp-content/uploads/2021/02/apple-store.svg"
                    alt="img"
                  />
                </a>
              </span>
              <div className='footer-grille-tarifaire'>
                <a
                    href={'/GRILLE-TARIFAIRE-ET-PROGRAMME.pdf'}
                    style={{ marginLeft: "0.5rem" }}
                    target="_blank"
                    rel="noreferrer">
                  <img
                      src={GrilleTarifaire}
                      alt="Grille tarifaire"
                  />
                </a>
              </div>
            </h6>
          </Grid>

          <Grid item xs={12} md={4} style={{ marginTop: "1rem" }}>
            <ul className="footer-ol no-bullet float-left float-lg-right">
              <li className="footer-li">(+221) 33 849 13 13</li>
              <li className="footer-li">(+221) 33 822 34 90</li>
              <li className="footer-li">Triangle sud x malick sy</li>
              <li className="footer-li">contact@rts.sn</li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
