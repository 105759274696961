import React from "react";
import infoimg from "../Header/images/rts/larts.png";
import { Link } from "react-router-dom";
import { sliceTitle } from "../Utils/CommonFunctions";
import "./index.css";

const EmbeddedNewSubItems = ({ posts, categorySlug }) => {
  const itemPart = (item, index) => {
    return (
      <div
        className={`${
          index % 4 === 0 ? "embedded-news-item-wrapper" : ""
        } columns pt-1 large-3 medium-6 small-12`}
        // className="columns pt-1 embedded-news-item-wrapper large-3 medium-6 small-12"
        key={index}>
        <div className="article-block small">
          <img
            src={item.acan_image_url || infoimg}
            alt="img"
            className="article-block-img small-img"
            style={{ borderRadius: "0.5rem" }}
          />
          <Link to={`/actualite/detail/${categorySlug}/${item.slug}`}>
            <p className="article-block-p small-link-title">
              {sliceTitle(item.title.rendered)}
            </p>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment key={Math.random()}>
      {posts.map((item, index) => {
        return itemPart(item, index);
      })}
    </React.Fragment>
  );
};

export default EmbeddedNewSubItems;
