import React, { useEffect, useState } from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { getData } from "../Helpers/apiHelper";
import LoadingSpinner from "./LoadingSpinner";

const SelectionTV = ({ title, url, isSelection, onSelectionChange }) => {
  const [lists, setLists] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(1);

  // 1 - Not Loading
  // 2 - No data
  // 3 - Loading icon

  useEffect(() => {
    setLoadingStatus(3);
    getBaseData();
  }, [url]);

  const getBaseData = async () => {
    const lists = await getData(url);
    if (lists) {
      setLists(lists.allitems);
      setLoadingStatus(1);
    } else {
      setLoadingStatus(2);
    }
  };

  return (
    <section className="selection-tv section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12} className="grey-back">
            <BlockTitle title={title} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // slidesPerView={1}
              rewind={true}
              navigation={true}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}>
              {lists.length > 0 ? (
                lists.map((emission, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="">
                        <Link
                          to={
                            "/emissions/" + emission.slug
                          }
                          onClick={() => onSelectionChange(emission)}>
                          <div className="cs-right">
                            <img
                              className="selection-img"
                              src={emission.logo_url}
                              alt={emission.title}
                              title={emission.title}
                            />
                            <div className="video-desc py-0">
                              <div className="video-title">
                                <h3 className="selection-title">
                                  {emission.title}
                                </h3>
                              </div>
                              {isSelection && (
                                <div className="video-play-button">
                                  <img
                                    src={emission.chaine_logo}
                                    alt={emission.title}
                                    className="replay-img"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <LoadingSpinner status={loadingStatus} />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default SelectionTV;
