import React, { useEffect, useState } from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import RadioLogo from "../Assets/images/radio.png";
import LoadingSpinner from "./LoadingSpinner";

const SelectionRadio = ({ url, title }) => {
  const [selectionRadios, setSelectionRadios] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(1);

  useEffect(() => {
    if (url) {
      setLoadingStatus(3);
      axios.get(url).then((res) => {
        const selectionRadios = res.data.allitems;
        if (selectionRadios.length) {
          setLoadingStatus(1);
          setSelectionRadios(selectionRadios);
        } else {
          setSelectionRadios([]);
          setLoadingStatus(2);
        }
      });
    }
  }, [url]);

  return (
    <section className="selection-radio section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12} className="grey-back">
            <BlockTitle title={title} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // slidesPerView={1}
              rewind={true}
              navigation={true}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}>
              {selectionRadios.length ? (
                selectionRadios.map((radio, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="">
                        <div className="cs-right">
                          <Link to={"/radios/emissionradio/" + radio.slug}>
                            <img
                              className="selection-img"
                              src={radio.logo_url}
                              alt={radio.title}
                              title={radio.title}
                            />
                            <div className="video-desc py-0">
                              <div className="video-title">
                                <h3 className="selection-title">
                                  {radio.title}
                                </h3>
                              </div>
                              <div className="video-play-button">
                                <img
                                  src={radio.radiologo || RadioLogo}
                                  alt={"logo"}
                                  className="replay-img"
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <LoadingSpinner status={loadingStatus} />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default SelectionRadio;
