import React from "react";
import Loader from "react-loader-spinner";

function Loading() {
  return (
    <div className="col-md-10 text-center">
      <Loader type="ThreeDots" color="#971B25" height={10} visible={true} />
    </div>
  );
}

export default Loading;
