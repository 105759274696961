export const base_url = "https://tveapi.acan.group/myapiv2/";
export const base_url1 = "https://dev.acangroup.org/demo/acanvoddev/myapiv2/";
export const api_google = "AIzaSyBIOEqynU3j0OFv-OdBihZc8CdB23M93Fw";
export const channelId = "eutelsatvideo";
export const apiyoutubevideo =
  "https://www.googleapis.com/youtube/v3/search?key=";
export const param = "&part=snippet,id&order=date&maxResults=50";
export const apiyoutubeplaylist =
  "https://www.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=";
export const wpJsonUrl = "https://actu.rts.sn/wp-json/wp/v2";
export const bannerCode1 = "alauneweb";
export const bannerCode2 = "kjh24sdsah";
export const bannerCode3 = "r1wdbhsc63c3m2";
