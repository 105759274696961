import React, { useEffect, useState } from "react";
import { getData } from "../Helpers/apiHelper";
import infoimg from "../Header/images/rts/larts.png";
import { Link } from "react-router-dom";
import { sliceTitle, decodeHtmlCharCodes } from "../Utils/CommonFunctions";
import Loading from "./Loading";
import { Grid, Button } from "@material-ui/core";
import Loader from "react-loader-spinner";
import "./index.css";

const NewsItem = ({ url, categorySlug, hasSlug }) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getPosts = async () => {
      const cateUrl = `${url}&per_page=9`;
      const res = await getData(cateUrl);
      if (res && res.length > 0) {
        setPosts(res);
      }
    };
    getPosts();
  }, [url]);

  const increaseSize = async () => {
    setIsLoading(true);
    setPage(page + 1);
    const newUrl = `${url}&per_page=9&page=${page + 1}`;
    const res = await getData(newUrl);
    if (res && res.length > 0) {
      setPosts((oldArray) => [...oldArray, ...res]);
    }
    setIsLoading(false);
  };

  const itemPart = (item, index) => {
    return (
      <div
        className={`${
          index === 0 ? "large-8 medium-12" : "large-4 medium-6 small-12"
        } columns pt-1 news-item-wrapper`}
        key={index}>
        <div className={`article-block ${index === 0 ? "big" : "small"}`}>
          <img
            src={item.acan_image_url || infoimg}
            alt="img"
            className={`article-block-img ${
              index === 0 ? "big-img" : "small-img"
            }`}
          />
          <Link to={`/actualite/detail/${categorySlug}/${item.slug}`}>
            <p
              className={`article-block-p ${
                index === 0 ? "big-link-title" : "small-link-title"
              }`}>
              {index === 0
                ? decodeHtmlCharCodes(sliceTitle(item.title.rendered, 100))
                : decodeHtmlCharCodes(sliceTitle(item.title.rendered, 94))}
            </p>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment key={Math.random()}>
      {posts.length > 0 ? (
        posts.map((item, index) => {
          return itemPart(item, index);
        })
      ) : (
        <Loading />
      )}
      <Grid container>
        <Grid item xs={12} className="text-center charger">
          <Button onClick={increaseSize}>
            {isLoading ? (
              <Loader
                type="ThreeDots"
                color="#971B25"
                height={10}
                visible={true}
              />
            ) : (
              "Plus +"
            )}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default NewsItem;
