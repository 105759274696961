import React from "react";
import Loader from "react-loader-spinner";

function LoadingSpinner({ status }) {
  return (
    <div className="col-md-10 text-center">
      {status === 3 ? (
        <Loader type="ThreeDots" color="#971B25" height={10} visible={true} />
      ) : (
        <h4>Aucune donnée disponible</h4>
      )}
    </div>
  );
}

export default LoadingSpinner;
