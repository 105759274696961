import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import FooterCopyright from "../Components/FooterCopyright";
import ScrollToTop from "react-scroll-to-top";

function Layout(props) {
  useEffect(() => {
    const hoverJs = document.createElement("script");
    const menuJs = document.createElement("script");
    hoverJs.src = "/js/min//hoverIntent.min.js";
    menuJs.src = "/js/min//quadmenu.min.js";

    hoverJs.async = true;
    document.body.appendChild(hoverJs);

    menuJs.async = true;
    document.body.appendChild(menuJs);
  }, []);
  return (
    <React.Fragment>
      <ScrollToTop smooth color="#C50806" style={{ borderRadius: "50%" }} />
      <Header />
      {props.children}
      <Footer />
      <FooterCopyright />
    </React.Fragment>
  );
}

export default withRouter(Layout);
