import React, { useEffect, useState } from "react";
import { getData } from "../Helpers/apiHelper";
import { Grid } from "@material-ui/core";
import SpotlightView from "./SpotlightView";
import EmbeddedNewSubItems from "./EmbeddeeNewsSubItems";
import Loading from "./Loading";

function EmbeddedNewsContents({ categoryUrl, slug }) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    setIsLoading(true);
    const res = await getData(categoryUrl + "&per_page=13&page=1&_embed");
    if (res && res.length > 0) {
      setPosts(res);
    }
    setIsLoading(false);
  };

  return (
    <>
      {posts.length > 0 ? (
        <React.Fragment key={slug}>
          <Grid container style={{ padding: "20px" }}>
            <Grid item xs={12} md={7}>
              <div className="anwp-pg-wrap">
                <div className="d-flex anwp-row flex-wrap no-gutters anwp-pg-hero-block anwp-pg-posts-wrapper anwp-pg-gutter--thin">
                  <div className="anwp-col-sm-12 anwp-pg-height-2x anwp-pg-hero-main-block-wrapper">
                    <SpotlightView data={posts[0]} type={1} />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="anwp-pg-wrap">
                <div className="d-flex anwp-row flex-wrap no-gutters anwp-pg-hero-block anwp-pg-posts-wrapper anwp-pg-gutter--thin">
                  {posts.length > 1 &&
                    posts.slice(1, 5).map((item, index) => {
                      if (item) {
                        return (
                          <div
                            key={index}
                            className={`featured-thumbnail anwp-pg-hero-secondary-block-wrapper ${
                              index % 2 === 1 ? "d-featured-none" : ""
                            }`}>
                            <SpotlightView data={item} type={0} />
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </Grid>
          </Grid>
          {posts.length > 5 && (
            <EmbeddedNewSubItems posts={posts.slice(5)} categorySlug={slug} />
          )}
        </React.Fragment>
      ) : isLoading ? (
        <Loading />
      ) : (
        ""
      )}
    </>
  );
}

export default EmbeddedNewsContents;
