import React, { useEffect, useState } from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { base_url } from "../Model/config";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import { getData } from "../Helpers/apiHelper";

const ReplayTV = ({ title, type }) => {
  // type = 1 => replay tv
  // type = 2 => replay tv copy
  const [lists, setLists] = useState([]);
  const [tvLists, setTvLists] = useState([]);
  useEffect(() => {
    getBaseData();
  }, []);

  const getBaseData = async () => {
    const urlSuffix =
      type === 2
        ? "listItemsByChannel/larts/370/json"
        : "alauneByGroup/larts/json";
    const tvs = await getData(`${base_url}listLiveTV/larts/json`);
    const lists = await getData(`${base_url}${urlSuffix}`);
    if (tvs) setTvLists(tvs.allitems);
    if (lists) setLists(lists.allitems);
  };

  const getChannelImg = (id) => {
    const res = tvLists.find((i) => i.id === id);
    if (res) {
      return res.logo_url;
    }
    return "";
  };

  return (
    <section className="replay_tv section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12} className="grey-back">
            <BlockTitle title={title} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // slidesPerView={3}
              rewind={true}
              navigation={true}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}>
              {lists ? (
                lists.map((allune, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="">
                        <Link to={"/dernierevideo/" + allune.slug}>
                          <div className="cs-right">
                            <img
                              src={allune.logo}
                              alt={allune.title}
                              title={allune.title}
                              className="position-relative"
                            />
                            {tvLists.length > 0 && (
                              <img
                                src={getChannelImg(allune.chaine_id)}
                                alt="channel"
                                className="replay-image"
                              />
                            )}
                            <div className="video-desc">
                              <div className="video-title">
                                <h3>{allune.title}</h3>
                              </div>
                              <div className="video-play-button">
                                <PlayCircleFilled />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <Loading />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ReplayTV;
