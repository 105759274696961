import React, { useEffect, useState } from "react";
import rtslogo from "../Header/images/rts/larts1.png";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./front.css";
import { base_url, wpJsonUrl } from "../Model/config";
import axios from "axios";
import { getData } from "../Helpers/apiHelper";

function Navbar() {
  const [radioLists, setRadioLists] = useState([]);
  const [archives, setArchives] = useState([]);
  const [tvs, setTvs] = useState([]);

  useEffect(() => {
    axios.get(base_url + "listLiveRadios/larts/json").then((res) => {
      const radioLists = res.data.allitems;
      setRadioLists(radioLists);
    });

    axios
      .get(
        `${wpJsonUrl}/categories?per_page=12&parent=0&orderby=count&order=desc`
      )
      .then((res) => {
        if (res.data && res.data.length) {
          setArchives(res.data);
        }
        // const archives = res.data.allitems;
        // setArchives(archives);
      });
    getBaseData();
  }, []);

  const getBaseData = async () => {
    const tvObj = await getData(base_url + "listLiveTV/larts/json");
    const tvList = tvObj.allitems;
    if (tvList.length) {
      setTvs(tvList);
    }
  };

  return (
    <section
      className="elementor-element elementor-element-dbbd7dd elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="dbbd7dd"
      data-element_type="section"
      style={{ padding: "5px 0" }}
      data-settings='{"background_background":"classic","ekit_has_onepagescroll_dot":"yes"}'>
      <div className="elementor-container elementor-column-gap-no">
        {/* <Container> */}
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-37cdd02 elementor-column elementor-col-25 elementor-top-column nav-logo"
            data-id="37cdd02"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'>
            <div className="elementor-column-wrap  elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-b12fcb9 elementor-widget elementor-widget-digiqole-logo elementor-sticky"
                  data-id="b12fcb9"
                  data-element_type="widget"
                  data-settings='{"sticky":"top","sticky_parent":"yes","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
                  data-widget_type="digiqole-logo.default">
                  <div className="elementor-widget-container">
                    <div className="digiqole-widget-logo">
                      <Link to="/" className="logo">
                        <img
                          src={rtslogo}
                          alt="RADIO TELEVISION SENEGALAISE"
                          className="nav-image"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="elementor-element elementor-element-d3436bb elementor-column elementor-col-75 elementor-top-column nav-collections"
            data-id="d3436bb"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'>
            <div className="elementor-column-wrap  elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-fe3cdf7 elementor-widget elementor-widget-quadmenu"
                  data-id="fe3cdf7"
                  data-element_type="widget"
                  data-widget_type="quadmenu.default">
                  <div className="elementor-widget-container">
                    <nav
                      id="quadmenu"
                      className="quadmenu-default_theme quadmenu-v2.1.1 quadmenu-align-center quadmenu-divider-show quadmenu-carets-show quadmenu-background-color quadmenu-mobile-shadow-hide quadmenu-dropdown-shadow-show quadmenu-hover-ripple quadmenu-notouch js quadmenu-sticky-animation quadmenu-is-horizontal"
                      data-template="collapse"
                      data-theme="default_theme"
                      data-unwrap="0"
                      data-width=""
                      data-selector=""
                      data-breakpoint="768"
                      data-sticky="1"
                      data-sticky-offset="100">
                      <div className="quadmenu-container">
                        <div className="quadmenu-navbar-header">
                          <button
                            type="button"
                            className="quadmenu-navbar-toggle collapsed"
                            data-quadmenu="collapse"
                            data-target="#quadmenu_0"
                            aria-expanded="false"
                            aria-controls="quadmenu">
                            <span className="icon-bar-container">
                              <span className="icon-bar icon-bar-top"></span>
                              <span className="icon-bar icon-bar-middle"></span>
                              <span className="icon-bar icon-bar-bottom"></span>
                            </span>
                          </button>
                        </div>
                        <div
                          id="quadmenu_0"
                          className="quadmenu-navbar-collapse collapse"
                          aria-expanded="false">
                          <ul className="quadmenu-navbar-nav">
                            <li
                              id="menu-item-7193"
                              className="quadmenu-item-7193 quadmenu-item quadmenu-item-object-mega quadmenu-item-has-children quadmenu-item-type-mega quadmenu-item-level-0 quadmenu-dropdown quadmenu-has-caret quadmenu-has-title quadmenu-has-link quadmenu-dropdown-right">
                              <Link
                                title="Télévision RTS"
                                to="#"
                                className="quadmenu-dropdown-toggle hoverintent">
                                <span className="quadmenu-item-content">
                                  <span className="quadmenu-caret"></span>
                                  <span className="quadmenu-text  hover t_1000">
                                    Télévision
                                  </span>
                                </span>
                              </Link>
                              <div
                                id="dropdown-7193"
                                className="quadmenu_btt t_300 quadmenu-dropdown-menu quadmenu-dropdown-stretch-boxed">
                                <ul className="quadmenu-row">
                                  {tvs.length > 0 &&
                                    tvs.map((tv, index) => {
                                      return (
                                        <li
                                          id="menu-item-7391"
                                          key={index}
                                          className="quadmenu-item-7391 quadmenu-item quadmenu-item-object-column quadmenu-item-has-children quadmenu-item-type-column col-xs-12 col-sm-2">
                                          <div id="dropdown-7391" className="">
                                            <ul>
                                              <li
                                                id="menu-item-7397"
                                                className="quadmenu-item-7397 quadmenu-item quadmenu-item-object-widget quadmenu-item-type-widget">
                                                <div className="quadmenu-item-widget widget widget_media_image">
                                                  <a href={"/tv/" + tv.slug}>
                                                    <img
                                                      width="60"
                                                      height="60"
                                                      className="image wp-image-7403 attachment-full size-full quadmenu-lazyload"
                                                      alt={tv.title}
                                                      loading="lazy"
                                                      style={{
                                                        maxWidth: "100%",
                                                        height: " auto",
                                                      }}
                                                      src={tv.logo_url}
                                                    />
                                                  </a>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </li>
                            <li
                              id="menu-item-7194"
                              className="quadmenu-item-7194 quadmenu-item quadmenu-item-object-carousel quadmenu-item-has-children quadmenu-item-type-carousel quadmenu-item-level-0 quadmenu-dropdown quadmenu-has-caret quadmenu-has-title quadmenu-has-link quadmenu-dropdown-right">
                              <Link
                                to="#"
                                className="quadmenu-dropdown-toggle hoverintent">
                                <span className="quadmenu-item-content">
                                  <span className="quadmenu-caret"></span>
                                  <span className="quadmenu-text  hover t_1000">
                                    Radio
                                  </span>
                                </span>
                              </Link>
                              <div
                                id="dropdown-7194"
                                className="quadmenu_btt t_300 quadmenu-dropdown-menu quadmenu-dropdown-stretch-boxed">
                                <Swiper
                                  className="nav-slide"
                                  spaceBetween={45}
                                  modules={[
                                    Navigation,
                                    Pagination,
                                    Scrollbar,
                                    A11y,
                                  ]}
                                  rewind={true}
                                  navigation={true}
                                  breakpoints={{
                                    1300: {
                                      slidesPerView: 4,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                      width: 768,
                                      slidesPerView: 2,
                                      spaceBetween: 45,
                                    },
                                    // when window width is >= 640px
                                    640: {
                                      width: 640,
                                      slidesPerView: 2,
                                      spaceBetween: 45,
                                    },
                                  }}>
                                  {radioLists.map((radio, i) => {
                                    if (radioLists.length > 0) {
                                      return (
                                        <SwiperSlide key={i}>
                                          <div className="">
                                            <Link
                                              to={"/radios/live/" + radio.slug}>
                                              <div
                                                className="cs-right"
                                                style={{ textAlign: "center" }}>
                                                <img
                                                  className="radio-list-img"
                                                  src={radio.sd_logo}
                                                  alt={radio.title}
                                                  title={radio.title}
                                                />
                                              </div>
                                            </Link>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    }
                                  })}
                                </Swiper>
                              </div>
                            </li>
                            <li
                              id="menu-item-7288"
                              className="quadmenu-item-7288 quadmenu-item quadmenu-item-object-mega quadmenu-item-has-children quadmenu-item-type-mega quadmenu-item-level-0 quadmenu-dropdown quadmenu-has-caret quadmenu-has-title quadmenu-has-link quadmenu-dropdown-right">
                              <Link
                                to="/actualite"
                                className="quadmenu-dropdown-toggle hoverintent">
                                <span className="quadmenu-item-content">
                                  <span className="quadmenu-caret"></span>
                                  <span className="quadmenu-text  hover t_1000">
                                    ACTUALITÉ
                                  </span>
                                </span>
                              </Link>
                              <div
                                id="dropdown-7288"
                                className="quadmenu_btt t_300 quadmenu-dropdown-menu quadmenu-dropdown-stretch-boxed">
                                <ul className="quadmenu-row">
                                  {archives.map((archive, index) => {
                                    if (archives.length > 0) {
                                      return (
                                        <li
                                          id="menu-item-7289"
                                          key={index}
                                          className="quadmenu-item-7289 quadmenu-item quadmenu-item-object-column quadmenu-item-has-children quadmenu-item-type-column col-xs-12 col-sm-2">
                                          <div id="dropdown-7289" className="">
                                            <ul>
                                              <li
                                                id="menu-item-7598"
                                                className="quadmenu-item-7598 quadmenu-item quadmenu-item-object-page quadmenu-item-type-post_type quadmenu-item-type-post_type quadmenu-item-level-2 quadmenu-has-title quadmenu-has-link quadmenu-has-background quadmenu-dropdown-right">
                                                <Link
                                                  to={`/actualite/${archive.slug}`}>
                                                  <span className="quadmenu-item-content">
                                                    <span className="quadmenu-text  hover t_1000">
                                                      {archive.name}
                                                    </span>
                                                  </span>
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                            </li>
                            <li
                              id="menu-item-7285"
                              className="quadmenu-item-7285 quadmenu-item quadmenu-item-object-mega quadmenu-item-type-mega quadmenu-item-level-0 quadmenu-has-title quadmenu-has-link quadmenu-dropdown-right">
                              <Link to={"/emissions"}>
                                <span className="quadmenu-item-content">
                                  <span className="quadmenu-text  hover t_1000">
                                    REPLAY
                                  </span>
                                </span>
                              </Link>
                            </li>
                            <li
                              id="menu-item-7286"
                              className="quadmenu-item-7286 quadmenu-item quadmenu-item-object-mega quadmenu-item-type-mega quadmenu-item-level-0 quadmenu-has-title quadmenu-has-link quadmenu-dropdown-right">
                              <Link to={"/programs"}>
                                <span className="quadmenu-item-content">
                                  <span className="quadmenu-text  hover t_1000">
                                    Programmes
                                  </span>
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Container> */}
      </div>
    </section>
  );
}

export default Navbar;
