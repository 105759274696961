import React, { useEffect, useState, useRef } from "react";
import FlashInfo from "../Components/FlashInfo";
import RadioChannelsSwiper from "../Components/RadioChannelsSwiper";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import { base_url } from "../Model/config";
import { Grid, Container, Switch } from "@material-ui/core";
import AdvertImg from "../Assets/images/small_advert.png";
import {
  PauseCircleOutline,
  PauseOutlined,
  PlayCircleOutline,
} from "@material-ui/icons";
import PodcastRadio from "../Components/PodcastRadio";
import SelectionRadio from "../Components/SelectionRadio";
import { urlSlug } from "../Utils/CommonFunctions";
import WaveSurfer from "wavesurfer.js";
import Loading from "../Components/Loading";
import LoadingSpinner from "../Components/LoadingSpinner";
import AdvertiseImage from "../Components/AdvertiseImage";
import { peaks } from "./peaks.js";
import EmbeddedNews from "../Components/EmbeddedNews";
import { bannerCode2 } from "../Model/config";
import "./index.css";

const Radio = () => {
  const [loadingStatus, setLoadingStatus] = useState(1);
  const [radios, setRadios] = useState([]);
  const [channel, setChannel] = useState(null);
  const [streamUrl, setStreamUrl] = useState("");
  const [podData, setPodData] = useState({ url: "", title: "" });
  const [selectionData, setSelectionData] = useState({ url: "", title: "" });
  const [radioInfo, setRadioInfo] = useState({ title: "", desc: "" });
  const [bannerUrl, setBannerUrl] = useState("");

  const [waveSurfer, setWaveSurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const urlType = urlSlug(2);
  const title = urlSlug(1);

  const waveformRef = useRef();

  useEffect(() => {
    setLoadingStatus(3);
    clearData();
    getMandatoryData();
    getBanner();
    if (!waveSurfer) {
      setWaveSurfer(
        WaveSurfer.create({
          container: "#waveform",
          barWidth: 4,
          cursorWidth: 1,
          normalize: true,
          backend: "MediaElement",
          height: 180,
          progressColor: "#4a74a5",
          responsive: true,
          waveColor: "#971B25",
          cursorColor: "transparent",
        })
      );
    }
  }, [urlType, title]);

  useEffect(() => {
    if (waveSurfer && streamUrl) {
      const aud = document.querySelector("#song");
      waveSurfer.load(aud, peaks);
      togglePlayPause();
    }
  }, [waveSurfer, streamUrl]);

  const getBanner = async () => {
    const url = await getBannerUrl(bannerCode2);
    if (url) setBannerUrl(url);
  };

  const clearData = async () => {
    setRadioInfo({ title: "", desc: "" });
    setPodData({ url: "", title: "" });
    setSelectionData({ url: "", title: "" });
    setStreamUrl("");
    setIsPlaying(false);
  };

  const togglePlayPause = () => {
    waveSurfer.playPause();
    setIsPlaying(!isPlaying);
  };

  const getMandatoryData = async () => {
    const liveRadios = await getData(`${base_url}listLiveRadios/larts/json`);
    if (liveRadios.allitems && liveRadios.allitems.length > 0) {
      const radioList = liveRadios.allitems;
      setRadios(radioList);

      switch (urlType) {
        case "live":
          const data = radioList.find((item) => item.slug === title);
          if (data) {
            setStreamUrl(data.stream_url);
            setLoadingStatus(1);
            setPodData({
              url: data.feed_url,
              title: data.title,
            });
            setSelectionData({
              url: data.emissions,
              title: data.title,
            });
            setRadioInfo({ title: data.title, desc: data.desc });
          } else {
            setLoadingStatus(2);
          }
          break;
        case "emissionradio":
          const selectionObj = await getData(base_url + "listshows/larts/json");
          const selectionList = selectionObj.allitems;
          if (selectionList.length) {
            const selectedData = selectionList.find(
              (item) => item.slug === title
            );
            if (selectedData) {
              const podObj = await getData(selectedData.feed_url);
              const podList = podObj.allitems;
              if (podList.length) {
                setLoadingStatus(1);
                setRadioInfo({
                  title: podList[0].title,
                  desc: podList[0].desc,
                });
                setStreamUrl(podList[0].radio_url);
              } else {
                setStreamUrl("");
                setLoadingStatus(2);
              }

              setPodData({
                url: selectedData.feed_url,
                title: selectedData.radio || "",
              });
              setSelectionData({
                url: base_url + "listshows/larts/json",
                title: selectedData.radio,
              });
            } else {
              setLoadingStatus(2);
            }
          }
          break;
        case "dernierpodcast":
          const podObj = await getData(
            `${base_url}details/aoditem/${title}/larts/json`
          );
          if (podObj) {
            setLoadingStatus(1);
            setRadioInfo({
              title: podObj.details.title,
              desc: podObj.details.desc,
            });
            setStreamUrl(podObj.details.radio_url);
            setSelectionData({
              url: podObj.links.listshowsbyradio,
              title: podObj.details.radio,
            });
            setPodData({
              url: base_url + "latestaoditemsByGroupe/larts/json",
              title: podObj.details.radio,
            });
          } else {
            setLoadingStatus(2);
          }
          break;
        default:
          break;
      }
    } else {
      setRadios([]);
    }
  };

  return (
    <div>
      <FlashInfo />

      {radios.length > 0 && (
        <RadioChannelsSwiper radios={radios} channel={title} />
      )}

      {/* Radio Playing Part */}
      <section className="selection-tv section-mt">
        <Container>
          <Grid container className="flash-title">
            <Grid
              item
              xs={12}
              className="grey-back text-center radio-play-wrapper">
              <div ref={waveformRef} id="waveform" />
              <audio
                id="song"
                src={streamUrl}
                controls={true}
                autoPlay
                style={{ display: "none" }}
              />
              {streamUrl ? (
                <>
                  <div>
                    {isPlaying ? (
                      <PauseCircleOutline
                        className="radio-play-icon"
                        onClick={() => togglePlayPause()}
                      />
                    ) : (
                      <PlayCircleOutline
                        className="radio-play-icon"
                        onClick={() => togglePlayPause()}
                      />
                    )}
                  </div>
                  <div className="radio-play-title">
                    <span>EN DIRECT</span>
                    <span>- VOUS ECOUTEZ</span>
                    <span style={{ fontWeight: "700" }}>
                      {radioInfo.title ? " " + radioInfo.title : ""}
                    </span>
                  </div>
                  <div className="radio-play-desc">
                    {radioInfo.desc ? radioInfo.desc : ""}
                  </div>
                </>
              ) : (
                <LoadingSpinner status={loadingStatus} />
              )}
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* Advert Part */}
      <section className="selection-tv section-mt">
        <Container>
          <Grid container className="flash-title">
            {bannerUrl ? (
              <AdvertiseImage
                imageUrl={bannerUrl}
                sx={{ width: "70%", m: "auto" }}
              />
            ) : (
              <Loading />
            )}
          </Grid>
        </Container>
      </section>

      {/* News Part */}
      <section className="selection-tv section-mt">
        {/* <Container>
          <Grid container className="flash-title">
            <div className="title-border width-100">
              <h4>Actualité | RSI</h4>
            </div>
          </Grid>
        </Container> */}
        <EmbeddedNews title={"Actualité | RSI"} slug={"rsi"} />
      </section>

      {/* Podcast Radio */}
      <PodcastRadio
        url={podData.url && podData.url}
        title={`Podcasts Radio | ${podData.title ? podData.title : ""}`}
      />

      {/* Selection Radio */}
      <SelectionRadio
        url={selectionData.url && selectionData.url}
        title={`Sélection Radio | ${
          selectionData.title ? selectionData.title : ""
        }`}
      />
    </div>
  );
};

export default Radio;
