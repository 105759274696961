import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { getData } from "../Helpers/apiHelper";
import { wpJsonUrl } from "../Model/config";
import { decodeHtmlCharCodes } from "../Utils/CommonFunctions";
import axios from "axios";

const FlashInfo = () => {
  const [flashInfos, setFlashInfos] = useState([]);

  useEffect(() => {
    getInitialValues();
  }, []);

  const getFeaturedData = async (url) => {
    const newsList = await getData(url);
    console.log(newsList)
    if (newsList.length > 0) {
      setFlashInfos(newsList);
    }
  };

  const getInitialValues = async () => {
    axios
        .get(`${wpJsonUrl}/categories/?slug=a-la-une`)
        .then((featuredRes) => {
          const url = featuredRes.data[0]["_links"]["wp:post_type"][0]["href"];
          getFeaturedData(url + "&_embed");
        })
        .catch((err) => {
          console.log(err.message);
        });
  };

  return (
    <section className="flash-info">
      <Container>
        <div className="row flash-title">
          <div className="flash-desc">
            <p>FLASH INFOS</p>
            <Swiper
              className="flash-swiper"
              spaceBetween={50}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              rewind={true}
              navigation={true}
              autoplay={true}>
              {flashInfos.length > 0 &&
                flashInfos.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {decodeHtmlCharCodes(item.title.rendered) || ""}
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FlashInfo;
