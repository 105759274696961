import React, { useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import FlashInfo from "../Components/FlashInfo";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import { wpJsonUrl } from "../Model/config";
import FeaturedPart from "../Components/FeaturedPart";
import TwitterPart from "../Components/TwitterPart";
import Loading from "../Components/Loading";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { convertSlug2Title, decodeHtmlCharCodes } from "../Utils/CommonFunctions";
import infoimg from "../Header/images/rts/larts.png";
import ReactHtmlParser from "react-html-parser";
import AdvertiseImage from "../Components/AdvertiseImage";
import BottomAdvertImg from "../Assets/images/news_detail_bottom.png";
import { bannerCode2 } from "../Model/config";
import "./index.css";
import { KeyboardBackspace, Schedule } from "@material-ui/icons";

moment.locale("fr");

const Detail = () => {
  const [featuredInfo, setFeaturedInfo] = useState([]);
  const [post, setPost] = useState([]);
  const [bannerUrl, setBannerUrl] = useState("");

  let url = window.location.pathname;
  url = url.split("/");
  const slug = url[url.length - 1];
  const categorySlug = url[url.length - 2];

  useEffect(() => {
    const getPostDetail = async () => {
      const res = await getData(`${wpJsonUrl}/posts?slug=${slug}`);
      const infos = await getData(
        `${wpJsonUrl}/posts/?per_page=10&_embed=wp:term`
      );
      if (res && res.length > 0) {
        setPost(res);
      }
      if (infos && infos.length > 0) {
        setFeaturedInfo(infos);
      }
    };
    getPostDetail();
    getBanner();
  }, [slug]);

  const getBanner = async () => {
    const url = await getBannerUrl(bannerCode2);
    if (url) setBannerUrl(url);
  };

  return (
    <>
      <FlashInfo />
      {/* Advert Part */}
      <section className="selection-tv section-mt">
        <Container>
          <Grid container className="flash-title">
            {bannerUrl ? (
              <AdvertiseImage
                imageUrl={bannerUrl}
                sx={{ width: "70%", m: "auto" }}
              />
            ) : (
              <Loading />
            )}
          </Grid>
        </Container>
      </section>

      <section className="replay_tv section-mt">
        <Container className="mb-2">
          <Grid container className="flash-title">
            <Grid item xs={12}>
              <Link to="/actualite">
                <KeyboardBackspace className="back-arrow" />
              </Link>
            </Grid>
            <Grid item xs={12} md={9} className="detail-content">
              <Grid container>
                <Grid item xs={12}>
                  {post.length > 0 ? (
                    <>
                      <h4>{decodeHtmlCharCodes(post[0].title["rendered"]) || ""}</h4>
                      <div className="detail-category-time">
                        <span className="detail-category">
                          {convertSlug2Title(categorySlug)}
                        </span>
                        <span className="detail-time">
                          <Schedule className="news-detail-timer-icon" />
                          {moment(post[0].date).format("DD MMM YYYY")}
                        </span>
                      </div>

                      <br />
                      <div>
                        <div className={`article-block big`}>
                          <div className="detail-image">
                            <img
                              src={post[0].acan_image_url || infoimg}
                              alt="img"
                            />
                          </div>
                          <div className="detail-description">
                            {ReactHtmlParser(post[0].content.rendered)}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Loading />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              {featuredInfo.length > 0 && (
                <FeaturedPart
                  featuredData={featuredInfo}
                  startIndex={0}
                  endIndex={6}
                  hasPlus={false}
                />
              )}
              <TwitterPart />
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* Advert Part */}
      <section
        className="selection-tv section-mt"
        style={{ marginBottom: "2rem" }}>
        <Container>
          <Grid container className="flash-title">
            <AdvertiseImage
              imageUrl={BottomAdvertImg}
              sx={{ width: "70%", m: "auto" }}
            />
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default Detail;
