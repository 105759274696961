import React from "react";
import { Box } from "@material-ui/core";
import { getChannelColor } from "../../Utils/CommonFunctions";

function HeaderBorder({ channelType }) {
  return (
    <Box
      style={{
        backgroundColor: getChannelColor(parseInt(channelType.slice(-1))),
        height: "15px",
        width: "100%",
      }}></Box>
  );
}

export default HeaderBorder;
