import React, { useEffect, useState } from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import {
  Facebook,
  Instagram,
  LinkedIn,
  YouTube,
  Twitter, CloudDownload,
} from "@material-ui/icons";
import { Container } from "@material-ui/core";
import moment from "moment";
import "moment/locale/fr";
import Loader from "react-loader-spinner";
import Navbar from "./Navbar";
import axios from "axios";
import { base_url } from "../Model/config";
import "../Header/menu.css";
moment.locale("fr");

function Header() {
  const [tvs, setTvs] = useState([]);
  useEffect(() => {
    axios.get(base_url + "listLiveTV/larts/json").then((res) => {
      const tvs = res.data.allitems;
      setTvs(tvs);
    });
  }, []);

  return (
    <div className="off-canvas-wrapper">
      <div className="off-canvas-wrapper-inner" data-off-canvas-wrapper>
        {/*header*/}
        <div className="off-canvas-content" data-off-canvas-content>
          <header>
            {/* Top */}
            <section id="top" className="topBar test show-for-large">
              <Container>
                <div className="row sm-column">
                  <div className="large-3 small-12 columns center-sm">
                    <div className="header-socials">
                      <a
                        href="//www.facebook.com/RTS1.Senegal/"
                        target="_blank"
                        className="social-links" rel="noreferrer">
                        <Facebook className="social-m-icon" />
                      </a>
                      <a
                        href="//twitter.com/rts1_senegal?lang=fr"
                        target="_blank"
                        className="social-links" rel="noreferrer">
                        <Twitter className="social-m-icon" />
                      </a>
                      <a
                        href="https://www.instagram.com/rts1_senegal/"
                        target="_blank"
                        className="social-links" rel="noreferrer">
                        <Instagram className="social-m-icon" />
                      </a>
                      <a href="#" target="_blank" className="social-links">
                        <LinkedIn className="social-m-icon" />
                      </a>
                      <a
                        href="https://www.youtube.com/c/RTS2S%C3%A9n%C3%A9galofficiel"
                        target="_blank"
                        className="social-links" rel="noreferrer">
                        <YouTube className="social-m-icon m-font-big" />
                      </a>
                    </div>
                    <div className="header-calendar">
                      {/* <span>
                                                <CalendarTodayIcon className="social-m-cal m-font-big" />
                                            </span> */}
                      <span className="calendar-text">
                        {moment().format("DD MMM YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="large-3 small-12 columns center-sm align-center sm-mt-15" style={{display:"flex"}}>
                    <CloudDownload  className="calendar-text" />
                    <a href={'/GRILLE-TARIFAIRE-ET-PROGRAMME.pdf'}
                       target="_blank"
                       rel="noreferrer">Télécharger la grille tarifaire</a>
                  </div>
                  <div className="large-6 columns hide-sm">
                    <div className="top-button rts-wrapper float-right">
                      {/* RTS live stuffs Section */}
                      <span className="rts-title">
                        <span>
                          <PlayCircleOutlineIcon className="m-icon" />
                        </span>
                        <span>En Direct:</span>
                      </span>
                      {tvs.length ? (
                        tvs.map((tv, i) => (
                          <a href={"/tv/" + tv.slug} key={i}>
                            <img
                              src={tv.logo}
                              alt="regarder rts1 en direct"
                              style={{ height: "50px", width: "75px" }}
                              title="RTS1 en direct"></img>
                          </a>
                        ))
                      ) : (
                        <div className="col-md-10 text-center">
                          <Loader
                            type="ThreeDots"
                            color="#971B25"
                            height={10}
                            width={60}
                            visible={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </section>
            <Navbar />
          </header>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n    .read-more:hover {\n        background-color: #8A0000;\n        color: white;\n    }\n\n    .read-more {\n        border: 1px solid;\n        font-size: 15px;\n        font-weight: bold;\n        text-transform: uppercase;\n        border-radius: 1px;\n        height: 30px;\n        padding: 5px;\n    }\n",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
