const xml2js = require("xml2js");

const convertXmlToJson = (data) => {
  let obj;
  xml2js.parseString(data, { mergeAttrs: true }, (err, result) => {
    if (err) {
      throw err;
    }
    const json = JSON.stringify(result, null, 4);
    obj = JSON.parse(json);
  });
  return obj;
};

const sliceTitle = (title, length = 60) => {
  if (title.length > length) {
    return title.slice(0, length) + "...";
  }
  return title;
};

const convertSlug2Title = (slug) => {
  const newSlug = slug.replaceAll("-", " ");
  return newSlug.toUpperCase();
};

const clearTags = (text) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

const urlSlug = (position, outWord) => {
  let url = window.location.pathname;
  url = url.split("/");
  const slug = url[url.length - position];
  if (slug === outWord) {
    return false;
  }
  return slug;
};

const slugify = (text) =>
  text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

const decodeHtmlCharCodes = (str) =>
  str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
    String.fromCharCode(charCode)
  );

const getChannelColor = (id) => {
  switch (id) {
    case 2:
      return "#AA0188";
    case 3:
      return "#DC8204";
    case 4:
      return "#43AE01";
    case 5:
      return "#3789F0";
    default:
      return "#C50705";
  }
};

const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export {
  convertXmlToJson,
  sliceTitle,
  convertSlug2Title,
  clearTags,
  urlSlug,
  slugify,
  decodeHtmlCharCodes,
  getChannelColor,
  getParameterByName
};
