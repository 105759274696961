import React from "react";
import "./index.css";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";
import infoimg from "../Header/images/rts/larts.png";
import { decodeHtmlCharCodes } from "../Utils/CommonFunctions";
moment.locale("fr");

const SpotlightView = ({ data, type }) => {
  return (
    <div className="anwp-pg-post-teaser anwp-pg-post-teaser--layout-a anwp-pg-hero-main-block my-0">
      <div className="anwp-pg-post-teaser__thumbnail position-relative">
        <div
          className="anwp-pg-post-teaser__thumbnail-img anwp-pg-height-190"
          style={{
            backgroundImage: `url(${data.acan_image_url || infoimg})`,
          }}></div>
        <div className="anwp-pg-post-teaser__muted_bg"></div>
        <div className="anwp-pg-post-teaser__thumbnail-bg anwp-position-cover"></div>
        <div className="anwp-pg-post-teaser__content d-flex flex-column anwp-position-cover">
          <div className="anwp-pg-post-teaser__top-meta d-flex mb-2"></div>
          <div className="d-flex flex-column anwp-pg-post-teaser__category-column ml-auto">
            <div
              className="anwp-pg-category__wrapper-filled px-2 d-flex align-items-center anwp-pg-post-teaser__category-wrapper align-self-end"
              style={{ backgroundColor: "#1565C0" }}>
              <span>
                {data["_embedded"]["wp:term"][0].length > 1
                  ? data["_embedded"]["wp:term"][0][
                      data["_embedded"]["wp:term"][0].length - 1
                    ].name
                  : data["_embedded"]["wp:term"][0][0].name}
              </span>
            </div>
          </div>
          <div
            className={`anwp-pg-post-teaser__title anwp-font-heading mt-auto mb-1 ${
              type === 1 ? "anwp-font-heading" : "font-small"
            }`}>
            {decodeHtmlCharCodes(data.title.rendered)}
          </div>
          <div className="anwp-pg-post-teaser__bottom-meta mt-1 position-relative mb-2">
            <span className="posted-on m-0">
              <span className="screen-reader-text">Posted on</span>
              <time
                className="anwp-pg-published"
                dateTime="2022-06-16T17:36:56+01:00">
                {moment(data.date).format("DD MMMM YYYY")}
              </time>
              {/* <time className="anwp-pg-updated" datetime="2022-06-16T17:36:56+01:00">16 juin 2022</time> */}
            </span>
          </div>
        </div>
        <Link
          className="anwp-position-cover anwp-link-without-effects"
          to={
            "/actualite/detail/" +
            data._embedded["wp:term"][0][0].slug +
            "/" +
            data.slug
          }
          aria-hidden="true"></Link>
      </div>
    </div>
  );
};
export default SpotlightView;
