import React, { useState, useEffect } from "react";
import { bannerCode1, base_url } from "../../Model/config";
import { getBannerUrl, getData } from "../../Helpers/apiHelper";
import FlashInfo from "../../Components/FlashInfo";
import AdvertiseImage from "../../Components/AdvertiseImage";
import { Container, Button, Grid, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ProgramSwiper from "../../Components/ProgramSwiper";
import Loading from "../../Components/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  gridWrapper: {
    padding: "18px 0",
  },
  btnGroup: {
    "& > button:nth-child(2n)": {
      margin: "0 10px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 5px",
      },
    },
  },
  focusedBtn: {
    color: "white!important",
    background: "#C50705!important",
  },
  subBtn: {
    fontSize: "22px",
    color: "#000",
    width: "210px",
    height: "80px",
    background: "white",
    border: "none",
    lineHeight: "initial",
    borderRadius: "8px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      color: "#000",
      width: "150px",
      height: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      color: "#000",
      width: "120px",
      height: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      color: "#000",
      width: "80px",
      height: "40px",
    },
  },
}));

function Programs() {
  const classes = useStyles();
  const [bannerUrl, setBannerUrl] = useState("");
  const [todayTvList, setTodayTvList] = useState([]);
  const [tomorrowTvList, setTomorrowTvList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(2);

  useEffect(() => {
    getInitialValues();
  }, []);

  const getInitialValues = async () => {
    const url = await getBannerUrl(bannerCode1);
    if (url) setBannerUrl(url);

    const todayTvList = await getData(`${base_url}guidetvall/larts/today/json`);
    const tomorrowTvList = await getData(
      `${base_url}guidetvall/larts/tomorrow/json`
    );
    setTodayTvList(todayTvList);
    setTomorrowTvList(tomorrowTvList);
    setSelectedList(todayTvList);
  };

  const handleBtnClick = (id) => {
    if (id === 4) {
      setSelectedList(tomorrowTvList);
    } else {
      setSelectedList(todayTvList);
    }
    setSelectedItem(id);
  };

  return (
    <div style={{ marginBottom: "5rem" }}>
      <FlashInfo />
      {bannerUrl ? <AdvertiseImage imageUrl={bannerUrl} /> : <Loading />}

      {/* Button Groups */}
      <section className="selection-tv section-mt">
        <Container>
          <Grid container className={classes.gridWrapper}>
            <ButtonGroup className={classes.btnGroup}>
              <Button
                className={`${classes.subBtn} ${
                  selectedItem === 1 && classes.focusedBtn
                }`}
                onClick={() => handleBtnClick(1)}>
                Le matin
              </Button>
              <Button
                className={`${classes.subBtn} ${
                  selectedItem === 2 && classes.focusedBtn
                }`}
                onClick={() => handleBtnClick(2)}>
                Ce soir
              </Button>
              <Button
                className={`${classes.subBtn} ${
                  selectedItem === 3 && classes.focusedBtn
                }`}
                onClick={() => handleBtnClick(3)}>
                En Direct
              </Button>
              <Button
                className={`${classes.subBtn} ${
                  selectedItem === 4 && classes.focusedBtn
                }`}
                onClick={() => handleBtnClick(4)}>
                Demain
              </Button>
            </ButtonGroup>
          </Grid>
        </Container>
      </section>

      {/* Program Swiper */}
      {selectedList.length > 0 &&
        selectedList.map((item, index) => {
          return (
            <ProgramSwiper key={index} channelData={item} type={selectedItem} />
          );
        })}
    </div>
  );
}

export default Programs;
