import React from "react";
import { Grid, Card, Button } from "@material-ui/core";
import Loading from "./Loading";
import { AddCircleOutline } from "@material-ui/icons";
import moment from "moment";
import "moment/locale/fr";
import { decodeHtmlCharCodes } from "../Utils/CommonFunctions";
import { Link } from "react-router-dom";

moment.locale("fr");

const FeaturedPart = ({ featuredData, startIndex, endIndex, hasPlus }) => {
  return (
    <>
      {featuredData.length > 0 ? (
        <Grid container>
          <Grid item xs={12} className="d-flex justify-center">
            <Card className="spot-card">
              <h5>INFO EN CONTINU</h5>
              {featuredData.length > 5 &&
                featuredData.slice(startIndex, endIndex).map((item, index) => {
                  if (item) {
                    return (
                      <div className="card-info-detail" key={index}>
                        <Link
                          to={`/actualite/detail/${item["_embedded"]["wp:term"][0][0].slug}/${item.slug}`}
                          aria-hidden="true">
                          <p className="card-info-time">
                            {moment(item.date).format("DD MMM YYYY")}
                          </p>
                          <p className="card-info-text">
                            {decodeHtmlCharCodes(item.title.rendered) || ""}
                          </p>
                        </Link>
                      </div>
                    );
                  }
                })}
            </Card>
          </Grid>
          {hasPlus && (
            <Grid item xs={12} className="show-more">
              <Button onClick={() => (window.location.href = "/actualite")}>
                <AddCircleOutline className="plus-m-icon" />
                Plus de bréves
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FeaturedPart;
