import React from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import LoadingSpinner from "./LoadingSpinner";

const VideoListSwiper = ({ title, videos, status }) => {
  return (
    <section className="replay_tv section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12} className="grey-back">
            <BlockTitle title={title} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // slidesPerView={3}
              rewind={true}
              navigation={true}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}>
              {videos ? (
                videos.map((allune, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="">
                        <Link to={"/dernierevideo/" + allune.slug}>
                          <div className="cs-right">
                            <img
                              src={allune.logo}
                              alt={allune.title}
                              title={allune.title}
                              className="position-relative"
                            />
                            <div className="video-desc">
                              <div className="text-center width-100">
                                <h3>{allune.title}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <LoadingSpinner status={status} />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default VideoListSwiper;
