import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import allRoutes from "./routes/AllRoutes";
import MiddleWare from "./routes/Middleware";
import Layout from "./routes/Layout";
import Analytics from "./Components/common/Analytics";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

function App() {
  return (
    <>
      <Router>
        <Analytics />
        <Switch>
          {allRoutes.map((route, idx) => (
            <MiddleWare
              path={route.path}
              exact={route.exact}
              layout={Layout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
    </>
  );
}

export default App;
