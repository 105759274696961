module.exports = {
  version: "wavesurfer",
  peaks: [
    0.000030517578125, -0.000030517578125, 0.1414794921875, -0.22064208984375,
    0.092803955078125, -0.157745361328125, 0.089447021484375, -0.20977783203125,
    0.3035888671875, -0.1099853515625, 0.11651611328125, -0.204376220703125,
    0.106689453125, -0.1116943359375, 0.093048095703125, -0.079925537109375,
    0.132659912109375, -0.24359130859375, 0.130828857421875, -0.135498046875,
    0.083099365234375, -0.0689697265625, 0.10491943359375, -0.067291259765625,
    0.118499755859375, -0.1024169921875, 0.0584716796875, -0.153533935546875,
    0.09820556640625, -0.0557861328125, 0.21893310546875, -0.124542236328125,
    0.263397216796875, -0.107940673828125, 0.17425537109375, -0.267364501953125,
    0.23193359375, -0.217376708984375, 0.1845703125, -0.21697998046875,
    0.15826416015625, -0.180694580078125, 0.1790771484375, -0.11981201171875,
    0.284332275390625, -0.263092041015625, 0.153717041015625, -0.12542724609375,
    0.106414794921875, -0.131195068359375, 0.109619140625, -0.0897216796875,
    0.11712646484375, -0.14251708984375, 0.157135009765625, -0.069183349609375,
    0.077972412109375, -0.08782958984375, 0.40423583984375, -0.206817626953125,
    0.49212646484375, -0.3150634765625, 0.28387451171875, -0.33758544921875,
    0.20172119140625, -0.37847900390625, 0.26849365234375, -0.221588134765625,
    0.23175048828125, -0.202056884765625, 0.201263427734375, -0.169586181640625,
    0.355499267578125, -0.147369384765625, 0.186737060546875,
    -0.237030029296875, 0.18499755859375, -0.143402099609375, 0.229888916015625,
    -0.229644775390625, 0.19683837890625, -0.206451416015625, 0.14044189453125,
    -0.136016845703125, 0.154296875, -0.284515380859375, 0.1524658203125,
    -0.2332763671875, 0.17840576171875, -0.17156982421875, 0.15142822265625,
    -0.058929443359375, 0.367095947265625, -0.13677978515625, 0.099945068359375,
    -0.159210205078125, 0.21417236328125, -0.232635498046875, 0.08941650390625,
    -0.228057861328125, 0.101470947265625, -0.160675048828125, 0.044921875,
    -0.18109130859375, 0.100921630859375, -0.099761962890625, 0.320281982421875,
    -0.405975341796875, 0.338592529296875, -0.274322509765625, 0.226318359375,
    -0.275054931640625, 0.30560302734375, -0.1927490234375, 0.106719970703125,
    -0.190032958984375, 0.071624755859375, -0.151031494140625, 0.09716796875,
    -0.143646240234375, 0.17486572265625, -0.127685546875, 0.127197265625,
    -0.1099853515625, 0.0966796875, -0.072509765625, 0.16021728515625,
    -0.324005126953125, 0.149322509765625, -0.13787841796875, 0.105255126953125,
    -0.101165771484375, 0.488616943359375, -0.064971923828125,
    0.485198974609375, -0.343353271484375, 0.157745361328125, -0.31396484375,
    0.138824462890625, -0.1412353515625, 0.214874267578125, -0.187744140625,
    0.127166748046875, -0.187774658203125, 0.15673828125, -0.2137451171875,
    0.140045166015625, -0.158477783203125, 0.269500732421875,
    -0.201995849609375, 0.194854736328125, -0.156005859375, 0.154876708984375,
    -0.156982421875, 0.109100341796875, -0.212432861328125, 0.184234619140625,
    -0.169189453125, 0.069671630859375, -0.11578369140625, 0.116973876953125,
    -0.095184326171875, 0.058135986328125, -0.11712646484375, 0.130340576171875,
    -0.0723876953125, 0.06982421875, -0.07366943359375, 0.446136474609375,
    -0.464324951171875, 0.264556884765625, -0.283782958984375,
    0.325714111328125, -0.24859619140625, 0.3734130859375, -0.254913330078125,
    0.355224609375, -0.276397705078125, 0.1815185546875, -0.166168212890625,
    0.205230712890625, -0.118896484375, 0.343963623046875, -0.14825439453125,
    0.13189697265625, -0.220733642578125, 0.22760009765625, -0.17218017578125,
    0.107177734375, -0.203857421875, 0.219451904296875, -0.1846923828125,
    0.2021484375, -0.094451904296875, 0.090545654296875, -0.109954833984375,
    0.240936279296875, -0.1663818359375, 0.0218505859375, -0.129241943359375,
    0.05517578125, -0.06585693359375, 0.154937744140625, -0.092620849609375,
    0.1412353515625, 0, 0.081268310546875, -0.046356201171875, 0.0828857421875,
    -0.065765380859375, 0.114013671875, -0.15716552734375, 0.040435791015625,
    -0.0518798828125, 0.029388427734375, -0.079345703125, 0.011474609375,
    -0.06414794921875, 0.027435302734375, -0.044342041015625, 0.0301513671875,
    -0.03985595703125, 0.116912841796875, -0.035614013671875, 0.33392333984375,
    -0.177825927734375, 0.096038818359375, -0.177398681640625,
    0.158660888671875, -0.114166259765625, 0.090057373046875,
    -0.155487060546875, 0.15411376953125, -0.071380615234375, 0.05828857421875,
    -0.09423828125, 0.079345703125, -0.04876708984375, 0.045135498046875,
    -0.037445068359375, 0.05987548828125, -0.04766845703125, 0.03411865234375,
    -0.031890869140625, 0.0264892578125, -0.0133056640625, 0.045074462890625,
    -0.029022216796875, 0.0133056640625, -0.026885986328125, 0.0931396484375,
    -0.290679931640625, 0.1741943359375, -0.133514404296875, 0.0838623046875,
    -0.106475830078125, 0.196014404296875, -0.097442626953125, 0.15521240234375,
    -0.058837890625, 0.10650634765625, -0.131256103515625, 0.08868408203125,
    -0.193572998046875, 0.08428955078125, -0.208526611328125, 0.113616943359375,
    -0.0794677734375, 0.0760498046875, -0.0887451171875, 0.0545654296875,
    -0.0955810546875, 0.18682861328125, -0.162109375, 0.18646240234375,
    -0.19012451171875, 0.104736328125, -0.084991455078125, 0.143951416015625,
    -0.17608642578125, 0.08660888671875, -0.09429931640625, 0.075042724609375,
    -0.087982177734375, 0.050384521484375, -0.099609375, 0.12225341796875,
    -0.18682861328125, 0.119842529296875, -0.133087158203125, 0.069305419921875,
    -0.06951904296875, 0.100311279296875, -0.051544189453125, 0.04205322265625,
    -0.06573486328125, 0.077301025390625, -0.043701171875, 0.06927490234375,
    -0.066131591796875, 0.034637451171875, -0.04620361328125, 0.03424072265625,
    -0.036041259765625, 0.036041259765625, -0.035125732421875, 0.1846923828125,
    -0.271759033203125, 0.130340576171875, -0.119354248046875, 0.11688232421875,
    -0.1156005859375, 0.10980224609375, -0.11907958984375, 0.199615478515625,
    -0.136199951171875, 0.110809326171875, -0.088531494140625, 0.11505126953125,
    -0.095794677734375, 0.3468017578125, -0.16644287109375, 0.108001708984375,
    -0.25531005859375, 0.0982666015625, -0.11749267578125, 0.100677490234375,
    -0.127899169921875, 0.10345458984375, -0.09918212890625, 0.107269287109375,
    -0.10455322265625, 0.071380615234375, -0.073760986328125, 0.544769287109375,
    -0.391082763671875, 0.224609375, -0.2320556640625, 0.12603759765625,
    -0.175933837890625, 0.054901123046875, -0.286651611328125,
    0.446685791015625, -0.318023681640625, 0.151123046875, -0.1290283203125,
    0.12469482421875, -0.147216796875, 0.08367919921875, -0.132659912109375,
    0.1263427734375, -0.065704345703125, 0.072601318359375, -0.070404052734375,
    0.061981201171875, -0.13470458984375, 0.05230712890625, -0.132781982421875,
    0.048980712890625, -0.08734130859375, 0.121490478515625, -0.048828125,
    0.13360595703125, -0.11883544921875, 0.1031494140625, -0.053192138671875,
    0.059295654296875, -0.085968017578125, 0.071746826171875,
    -0.078826904296875, 0.038055419921875, -0.0633544921875, 0.028961181640625,
    -0.038848876953125, 0.0684814453125, -0.046356201171875, 0.116363525390625,
    -0.1231689453125, 0.115203857421875, -0.1422119140625, 0.09881591796875,
    -0.083831787109375, 0.067169189453125, -0.05206298828125, 0.051544189453125,
    -0.039215087890625, 0.045501708984375, -0.051177978515625,
    0.057159423828125, -0.054534912109375, 0.03582763671875, -0.046661376953125,
    0.03399658203125, -0.0584716796875, 0.085296630859375, -0.052154541015625,
    0.0545654296875, -0.043304443359375, 0.048828125, -0.069061279296875,
    0.051025390625, -0.03594970703125, 0.0517578125, -0.05352783203125,
    0.042999267578125, -0.089630126953125, 0.03424072265625, -0.063629150390625,
    0.05126953125, -0.075469970703125, 0.075225830078125, -0.086669921875,
    0.066650390625, -0.204498291015625, 0.123382568359375, -0.096893310546875,
    0.073822021484375, -0.139007568359375, 0.22265625, -0.161346435546875,
    0.123504638671875, -0.09930419921875, 0.219573974609375, -0.111572265625,
    0.206207275390625, -0.1197509765625, 0.21209716796875, -0.134185791015625,
    0.137237548828125, -0.204376220703125, 0.113006591796875,
    -0.448455810546875, 0.1605224609375, -0.122802734375, 0.106353759765625,
    -0.16497802734375, 0.12774658203125, -0.1434326171875, 0.140106201171875,
    -0.313629150390625, 0.3026123046875, -0.069091796875, 0.339691162109375,
    -0.26885986328125, 0.3280029296875, -0.227264404296875, 0.26641845703125,
    -0.237213134765625, 0.241119384765625, -0.2606201171875, 0.317718505859375,
    -0.161285400390625, 0.306396484375, -0.25640869140625, 0.358978271484375,
    -0.21875, 0.1650390625, -0.2630615234375, 0.377899169921875,
    -0.227752685546875, 0.317626953125, -0.16241455078125, 0.191741943359375,
    -0.137542724609375, 0.209991455078125, -0.253143310546875, 0.2615966796875,
    -0.1822509765625, 0.153167724609375, -0.0936279296875, 0.114410400390625,
    -0.17279052734375, 0.13934326171875, -0.133209228515625, 0.10162353515625,
    -0.109161376953125, 0.19329833984375, -0.1766357421875, 0.129608154296875,
    -0.20111083984375, 0.133331298828125, -0.26116943359375, 0.16058349609375,
    -0.2020263671875, 0.166473388671875, -0.341064453125, 0.30743408203125,
    -0.376800537109375, 0.29425048828125, -0.23968505859375, 0.19390869140625,
    -0.2120361328125, 0.14544677734375, -0.222137451171875, 0.265045166015625,
    -0.208404541015625, 0.256927490234375, -0.202178955078125, 0.28460693359375,
    -0.187957763671875, 0.27435302734375, -0.220733642578125, 0.383270263671875,
    -0.229705810546875, 0.245941162109375, -0.26092529296875, 0.19305419921875,
    -0.1658935546875, 0.285888671875, -0.18670654296875, 0.384735107421875,
    -0.249847412109375, 0.519744873046875, -0.45819091796875, 0.292236328125,
    -0.388763427734375, 0.322235107421875, -0.224945068359375, 0.21978759765625,
    -0.3955078125, 0.222412109375, -0.31451416015625, 0.20159912109375,
    -0.304534912109375, 0.207977294921875, -0.313507080078125,
    0.157623291015625, -0.177001953125, 0.177734375, -0.293426513671875,
    0.339569091796875, -0.04443359375, 0.211944580078125, -0.18994140625,
    0.13201904296875, -0.21185302734375, 0.143951416015625, -0.241943359375,
    0.1309814453125, -0.226226806640625, 0.30926513671875, -0.3580322265625,
    0.332550048828125, -0.23980712890625, 0.29638671875, -0.133575439453125,
    0.298370361328125, -0.35791015625, 0.0230712890625, -0.314422607421875,
    0.0843505859375, -0.274261474609375, 0.211669921875, -0.25531005859375,
    0.390380859375, -0.154449462890625, 0.218170166015625, -0.244354248046875,
    0.24139404296875, -0.281524658203125, 0.272735595703125, -0.21856689453125,
    0.37322998046875, -0.331695556640625, 0.24530029296875, -0.170654296875,
    0.24102783203125, -0.2760009765625, 0.20330810546875, -0.136383056640625,
    0.167144775390625, -0.241180419921875, 0.24395751953125, -0.10546875,
    0.197296142578125, -0.102325439453125, 0.253997802734375,
    -0.136444091796875, 0.169189453125, -0.16107177734375, 0.165924072265625,
    -0.2279052734375, 0.1468505859375, -0.090087890625, 0.177825927734375,
    -0.12371826171875, 0.113525390625, -0.143951416015625, 0.143035888671875,
    -0.116424560546875, 0.1484375, -0.131195068359375, 0.132080078125,
    -0.1951904296875, 0.17230224609375, -0.14007568359375, 0.56292724609375,
    -0.56011962890625, 0.399627685546875, -0.52081298828125, 0.353271484375,
    -0.7218017578125, 0.5157470703125, -0.542633056640625, 0.39599609375,
    -0.4151611328125, 0.478363037109375, -0.573455810546875, 0.668792724609375,
    -0.759735107421875, 0.5816650390625, -0.57965087890625, 0.42425537109375,
    -0.9305419921875, 0.3038330078125, -0.859283447265625, 0.42388916015625,
    -0.4117431640625, 0.44390869140625, -0.373870849609375, 0.63037109375,
    -0.3701171875, 0.309295654296875, -0.85284423828125, 0.275604248046875,
    -0.375701904296875, 0.25469970703125, -0.43157958984375, 0.389404296875,
    -0.248046875, 0.264556884765625, -0.670684814453125, 0.242340087890625,
    -0.426513671875, 0.527557373046875, -0.524383544921875, 0.50335693359375,
    -0.373046875, 0.338134765625, -0.809906005859375, 0.610626220703125,
    -0.4696044921875, 0.78472900390625, -0.4957275390625, 0.623809814453125,
    -0.731353759765625, 0.594207763671875, -0.602630615234375,
    0.612640380859375, -0.341644287109375, 0.43109130859375, -0.40283203125,
    0.47711181640625, -0.387603759765625, 0.7415771484375, -0.6998291015625,
    0.64410400390625, -0.644622802734375, 0.554443359375, -0.4976806640625,
    0.507049560546875, -0.4718017578125, 0.391082763671875, -0.3331298828125,
    0.62078857421875, -0.510040283203125, 0.4422607421875, -0.436004638671875,
    0.474029541015625, -0.575897216796875, 0.434326171875, -0.478515625,
    0.34747314453125, -0.705841064453125, 0.44140625, -0.627532958984375,
    0.754058837890625, -0.50146484375, 0.278289794921875, -0.328826904296875,
    0.430206298828125, -0.09600830078125, 0.362579345703125, -0.26324462890625,
    0.40765380859375, -0.134002685546875, 0.23883056640625, -0.360107421875,
    0.281768798828125, -0.27581787109375, 0.120635986328125, -0.236907958984375,
    0.11376953125, -0.3348388671875, 0.832733154296875, -0.624267578125,
    0.2354736328125, -0.280181884765625, 0.26519775390625, -0.28314208984375,
    0.18548583984375, -0.27587890625, 0.414459228515625, -0.34881591796875,
    0.25347900390625, -0.241485595703125, 0.26800537109375, -0.245849609375,
    0.210540771484375, -0.19158935546875, 0.16351318359375, -0.288421630859375,
    0.169464111328125, -0.391937255859375, 0.2940673828125, -0.345794677734375,
    0.7451171875, -0.369476318359375, 0.698272705078125, -0.254730224609375,
    0.3309326171875, -0.3157958984375, 0.420623779296875, -0.19696044921875,
    0.489990234375, -0.212554931640625, 0.268341064453125, -0.3101806640625,
    0.213958740234375, -0.222625732421875, 0.1824951171875, -0.229400634765625,
    0.175506591796875, -0.195404052734375, 0.095458984375, -0.356903076171875,
    0.1959228515625, -0.286956787109375, 0.226715087890625, -0.144622802734375,
    0.529144287109375, -0.82122802734375, 0.3875732421875, -0.570220947265625,
    0.25885009765625, -0.409912109375, 0.5953369140625, -0.42877197265625,
    0.61297607421875, -0.32867431640625, 0.566436767578125, -0.36358642578125,
    0.37591552734375, -0.309844970703125, 0.2861328125, -0.341827392578125,
    0.333831787109375, -0.296051025390625, 0.47674560546875, -0.42431640625,
    0.329925537109375, -0.264312744140625, 0.6064453125, -0.390289306640625,
    0.666168212890625, -0.308837890625, 0.457244873046875, -0.2586669921875,
    0.287109375, -0.24267578125, 0.31585693359375, -0.312042236328125,
    0.14752197265625, -0.332183837890625, 0.19464111328125, -0.298828125,
    0.216705322265625, -0.32421875, 0.147857666015625, -0.294525146484375,
    0.177459716796875, -0.491302490234375, 0.07354736328125, -0.411285400390625,
    0.200653076171875, -0.346405029296875, 0.67449951171875, -0.473876953125,
    0.46197509765625, -0.407196044921875, 0.23699951171875, -0.44891357421875,
    0.608734130859375, -0.565643310546875, 0.594757080078125,
    -0.547943115234375, 0.432586669921875, -0.53057861328125, 0.4224853515625,
    -0.197235107421875, 0.663055419921875, -0.567474365234375, 0.60772705078125,
    -0.387451171875, 0.614532470703125, -0.6326904296875, 0.358245849609375,
    -0.663848876953125, 0.68310546875, -0.2447509765625, 0.504058837890625,
    -0.669586181640625, 0.179351806640625, -0.5745849609375, 0.487548828125,
    -0.563690185546875, 0.732421875, -0.740020751953125, 0.323211669921875,
    -0.73931884765625, 0.56915283203125, -0.52337646484375, 0.366729736328125,
    -0.614654541015625, 0.44488525390625, -0.331573486328125, 0.489501953125,
    -0.301849365234375, 0.164398193359375, -0.48907470703125, 0.451416015625,
    -0.206512451171875, 0.929168701171875, -0.60870361328125, 0.4066162109375,
    -0.50592041015625, 0.410980224609375, -0.32232666015625, 0.5364990234375,
    -0.8680419921875, 0.637237548828125, -0.593780517578125, 0.312255859375,
    -0.43072509765625, 0.396697998046875, -0.3570556640625, 0.511688232421875,
    -0.6363525390625, 0.358978271484375, -0.638946533203125, 0.613250732421875,
    -0.456634521484375, 0.27825927734375, -0.42486572265625, 0.250091552734375,
    -0.95843505859375, 0.375335693359375, -0.92352294921875, 0.810028076171875,
    -0.665435791015625, 0.7501220703125, -0.423858642578125, 0.69097900390625,
    -0.684844970703125, 0.66473388671875, -0.65313720703125, 0.738525390625,
    -0.5560302734375, 0.71990966796875, -0.904296875, 0.6737060546875,
    -0.823089599609375, 0.89617919921875, -0.339599609375, 0.46270751953125,
    -0.916290283203125, 0.786651611328125, -0.517547607421875, 0.4193115234375,
    -0.29296875, 0.258880615234375, -0.327301025390625, 0.22833251953125,
    -0.218536376953125, 0.113555908203125, -0.24200439453125, 0.114654541015625,
    -0.141937255859375, 0.085205078125, -0.1422119140625, 0.085205078125,
    -0.059478759765625, 0.038665771484375, -0.043853759765625, 0.0201416015625,
    -0.030242919921875, 0.02044677734375, -0.0301513671875, 0.0211181640625,
    -0.013519287109375, 0.017578125, -0.0152587890625, 0.012176513671875,
    -0.012725830078125, 0.0089111328125, -0.008514404296875, 0.0050048828125,
    -0.00738525390625, 0.0035400390625, -0.003936767578125, 0.0035400390625,
    -0.002655029296875, 0.001708984375, -0.00250244140625, 0.00177001953125,
    -0.002166748046875, 0.001007080078125, -0.001800537109375, 0.00091552734375,
    -0.0008544921875, 0.00042724609375, -0.0003662109375, 0.0003662109375,
    -0.000244140625, 0.000030517578125, -0.000030517578125, 0.000030517578125,
    -0.000030517578125, 0, -0.000030517578125, 0.000030517578125,
    -0.000030517578125, 0, -0.000030517578125, 0.000030517578125,
    -0.000030517578125, 0, -0.000030517578125, 0, -0.000030517578125,
  ],
};
