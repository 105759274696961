import React from "react";
import { Grid, Card } from "@material-ui/core";
import moment from "moment";
import "moment/locale/fr";
import { TwitterTimelineEmbed } from "react-twitter-embed";
moment.locale("fr");

const TwitterPart = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} className="d-flex justify-center">
          <Card className="spot-card">
            <h5>LES TWEETS</h5>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="RTS1_Senegal"
              options={{ height: 1500 }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default TwitterPart;
