import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { base_url } from "../Model/config";
import ClapprWrapper from "../Utils/clapprreplay";
import ReactGA from "react-ga";
import "../Header/css/app.css";
import "../Header/css/theme.css";
import "../Header/css/custom.css";
import "../Header/css/font-awesome.min.css";
import "../Header/layerslider/css/layerslider.css";
import "../Header/css/owl.carousel.min.css";
import "../Header/css/owl.theme.default.min.css";
import "../Header/css/responsive.css";
import "../Header/css/custom-responsive.css";
import playbutton from "../Header/images/play-button.png";
import { Container, Divider, Grid } from "@material-ui/core";
import BlockTitle from "../Components/BlockTitle";
import SelectionTV from "../Components/SelectionTv";
import "./tv.css";
import { getData, getBannerUrl } from "../Helpers/apiHelper";
import LoadingSpinner from "../Components/LoadingSpinner";
import { urlSlug } from "../Utils/CommonFunctions";
import FlashInfo from "../Components/FlashInfo";
import EmbeddedNews from "../Components/EmbeddedNews";
import AdvertiseImage from "../Components/AdvertiseImage";
import TvDropDownBtn from "../Components/TvDropDownBtn";
import VideoListSwiper from "../Components/VideoListSwiper";
import LinkedChannelList from "../Components/LinkedChannelList";
import HeaderBorder from "../Components/common/HeaderBorder";
import { bannerCode1, bannerCode2, bannerCode3 } from "../Model/config";

// Components
import TvChannelsSwiper from "../Components/TvChannelsSwiper";
import Loading from "../Components/Loading";

class TVPage extends Component {
  state = {
    emissions: [],
    oneemissions: [],
    videos: [],
    tvs: [],
    linkedChannels: [],
    min: 0,
    max: 3,
    max1: 6,
    url: "",
    srctv: "",
    title: "",
    desc: "",
    channel: null,
    directData: null,
    loadingStatus: 1,
    tvSlug: "",
    path: "",
    isOpened: false,
    bannerUrl: "",
    topBannerUrl: "",
  };
  componentDidMount() {
    const url = window.location.pathname;
    this.setState({ loadingStatus: 3 });
    this.initializeVariables(url);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.path !== this.state.path) {
      this.setState({ loadingStatus: 3 });
      this.initializeVariables(this.state.path);
    }
  }

  initializeVariables = async (url) => {
    url = url.split("/");
    var userId = url[url.length - 1];
    var newstr3 = decodeURI(userId);
    var newstr4 = newstr3;
    const bannerCode = newstr4 === "rts-2" ? bannerCode2 : bannerCode3;
    const bannerUrl = await getBannerUrl(bannerCode);
    const topBannerUrl = await getBannerUrl(bannerCode1);
    this.setState({ bannerUrl, topBannerUrl });

    if (newstr4 === "tv") {
      this.setState({ tvSlug: newstr4 });
      await axios.get(base_url + "listLiveTV/larts/json").then(async (res) => {
        const tvs = res.data;
        const url = res.data.allitems[0].feed_url;
        const title = res.data.allitems[0].title;
        const desc = res.data.allitems[0].desc;
        const videourl = res.data.allitems[0].alaune_feed;
        this.setState({ tvs, title, desc, videourl, channel: tvs.allitems[0] });
        await this.getDirect(res.data.allitems[0].guidetvnow);

        await axios.get(url).then((res) => {
          const srctv = res.data.web_url;
          this.setState({ srctv });
        });
        await axios.get(res.data.allitems[0].alaune_feed).then((response) => {
          const videos = response.data;
          this.setState({ videos });
        });
      });
    } else {
      await axios.get(base_url + "listLiveTV/larts/json").then(async (res) => {
        const tvs = res.data;
        let tvChannel;
        let videoSlug = null;
        const tvChannelSlug = urlSlug(2);
        if (tvChannelSlug === "tv") {
          tvChannel = urlSlug(1);
          this.setState({ tvSlug: tvChannel });
        } else {
          tvChannel = tvChannelSlug;
          videoSlug = urlSlug(1);
          this.setState({ tvSlug: tvChannel });
        }

        for (let index = 0; index < res.data.allitems.length; index++) {
          if (res.data.allitems[index].slug === tvChannel) {
            if (tvChannel === "rts-2") {
              const linkedChannelUrl =
                res.data.allitems[index].list_channels_by_category;
              const linkedChannels = await getData(linkedChannelUrl);
              this.setState({ linkedChannels });
            }
            const url = res.data.allitems[index].feed_url;
            const title = res.data.allitems[index].title;
            const desc = res.data.allitems[index].desc;
            const videourl = res.data.allitems[index].alaune_feed;
            await this.getDirect(res.data.allitems[index].guidetvnow);
            if (!videoSlug) {
              this.setState({
                tvs,
                title,
                desc,
                videourl,
                channel: res.data.allitems[index],
              });
              axios.get(url).then((res) => {
                const srctv = res.data.web_url;
                this.setState({ srctv });
              });
            }
            await axios.get(videourl).then(async (response) => {
              const videos = response.data;
              if (videos.allitems.length === 0) {
                this.setState({ loadingStatus: 2 });
              } else {
                // If select one item in videos
                if (videoSlug) {
                  const selectedVideo = videos.allitems.find(
                    (item) => item.slug === videoSlug
                  );
                  if (selectedVideo) {
                    const videoSrcObj = await getData(selectedVideo.feed_url);
                    const videoSrc = videoSrcObj.video_url || "";
                    this.setState({
                      tvs,
                      title: selectedVideo.title,
                      desc: selectedVideo.desc,
                      srctv: videoSrc,
                      channel: res.data.allitems[index],
                    });
                  }
                }
                this.setState({ loadingStatus: 1, videos });
              }
            });
            break;
          }
        }
      });
    }
    ReactGA.initialize("UA-100085590-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // window.location.href = "#";
    axios.get(base_url + "listChannelsByChaine/1/larts/json").then((res) => {
      const emissions = res.data;
      this.setState({ emissions });
    });
  };

  getDirect = async (url) => {
    const directInfo = await getData(url);
    if (directInfo.direct && Object.keys(directInfo.direct).length !== 0) {
      this.setState({ directData: directInfo.direct });
    } else {
      this.setState({ directData: null });
    }
  };

  handleClick = (url, titre, description, e) => {
    //e.preventDefault();

    axios.get(url).then((res) => {
      const srctv = res.data.video_url;
      this.setState({ srctv, title: titre, desc: description });
    });
  };
  handleClick1 = (url, titre, description, alaune, e) => {
    e.preventDefault();

    axios.get(url).then((res) => {
      const srctv = res.data.web_url;
      this.setState({ srctv, title: titre, desc: description });
    });

    axios.get(alaune).then((response) => {
      const videos = response.data;
      this.setState({ videos });
    });
  };

  handleSelection = async (val) => {
    return;
  };

  handleUrlChange = async (url) => {
    this.setState({ loadingStatus: 3 });
    await this.initializeVariables(window.location.origin + url);
  };

  handleShowButtonChange = async () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  render() {
    const tvs = this.state.tvs.allitems;
    const videos = this.state.videos.allitems;

    // Thomas working part
    const {
      channel,
      srctv,
      directData,
      tvSlug,
      title,
      desc,
      isOpened,
      linkedChannels,
      bannerUrl,
      topBannerUrl,
    } = this.state;

    return (
      <div>
        <HeaderBorder channelType={tvSlug} />
        <FlashInfo />
        {!isOpened &&
          tvSlug !== "rts-2" &&
          (topBannerUrl ? (
            <AdvertiseImage imageUrl={topBannerUrl} />
          ) : (
            <Loading />
          ))}
        <TvDropDownBtn
          title={"DIRECT"}
          num={tvSlug.slice(-1)}
          isOpened={isOpened}
          onShowButtonChange={this.handleShowButtonChange}
        />
        {isOpened && (
          <>
            {/* Live Streaming part */}
            <section className="selection-radio section-mt">
              <Container>
                <div className="video-main">
                  <div className="row">
                    <div className="large-7 medium-6 columns">
                      <ClapprWrapper src={srctv} />
                    </div>
                    <div className="large-5 medium-6 columns">
                      {channel && (
                        <div className="video-info">
                          <img
                            src={channel.logo_url}
                            alt="channel_logo"
                            className="tv-list-img"
                          />
                          <h4 className="tv-list-channel">{title || ""}</h4>
                          <div className="tv-list-title">
                            <span className="tv-list-title-prefix">
                              En Direct -
                            </span>
                            {directData ? (
                              <>
                                <span className="tv-list-title-content">
                                  &nbsp;
                                  {directData.title}
                                </span>
                                <span className="tv-list-title-category">
                                  &nbsp;| {directData.categoryName}
                                  <br />
                                  &nbsp;présentée par
                                </span>
                                <span
                                  className="tv-list-title-content"
                                  style={{ color: "rgb(87 84 84)" }}>
                                  &nbsp;{directData.presentateurs}
                                </span>
                              </>
                            ) : (
                              <span className="tv-list-title-content">
                                {" Général"}
                              </span>
                            )}
                          </div>
                          <Divider className="tv-list-title-divider" />
                          <p className="tv-list-desc">{desc || ""}</p>
                          <div className="tv-social-link-wrapper text-center">
                            <div className="tv-social-desc">
                              <h3>Suivez-nous sur : </h3>
                            </div>
                            <div className="tv-social-links">
                              <div className="socialLinks">
                                <a
                                  href="//www.facebook.com/RTS1.Senegal/"
                                  target="_blank">
                                  <i className="fa fa-facebook-f" />
                                </a>
                                <a
                                  href="https://www.instagram.com/rts1_senegal/"
                                  target="_blank">
                                  <i className="fa fa-instagram" />
                                </a>
                                <a
                                  href="//twitter.com/rts1_senegal?lang=fr"
                                  target="_blank">
                                  <i className="fa fa-twitter" />
                                </a>
                                <a href="#">
                                  <i className="fa fa-whatsapp" />
                                </a>
                                <a
                                  href="https://www.youtube.com/c/RTS2S%C3%A9n%C3%A9galofficiel"
                                  target="_blank">
                                  <i className="fa fa-youtube" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </section>

            {/* Advertise 2 Image */}
            <section className="selection-radio section-mt">
              <Container>
                <Grid container className="flash-title">
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "15px", textAlign: "center" }}>
                    {bannerUrl ? (
                      <AdvertiseImage
                        imageUrl={bannerUrl}
                        sx={{ width: tvSlug === "rts-2" && "70%", m: "auto" }}
                      />
                    ) : (
                      <Loading />
                    )}
                  </Grid>
                </Grid>
              </Container>
            </section>

            {/* Channels Swiper Part */}
            {tvs ? (
              <TvChannelsSwiper
                tvs={tvs}
                channel={channel}
                onUrlChange={this.handleUrlChange}
              />
            ) : (
              <Loading />
            )}
          </>
        )}

        {/* Embedded News Part */}
        {tvSlug !== "rts-2" && tvSlug && (
          <EmbeddedNews
            title={`Actualité | RTS ${tvSlug.slice(-1)}`}
            slug={tvSlug}
          />
        )}

        {/* RTS2 Swiper List */}
        {tvSlug === "rts-2" && (
          <VideoListSwiper
            title={"SUR LA RTS2"}
            videos={videos}
            status={videos && videos.length === 0 ? 2 : 1}
          />
        )}

        {/* Selection TV */}
        {tvSlug !== "rts-2" && channel && (
          <SelectionTV
            title={`Sélection TV | ${channel.title}`}
            url={channel.vod_feed}
            isSelection={false}
            onSelectionChange={this.handleSelection}
          />
        )}

        {/* RTS2 only categories */}
        {tvSlug === "rts-2" &&
          linkedChannels &&
          linkedChannels.length > 0 &&
          linkedChannels.map((item, index) => {
            return (
              <LinkedChannelList
                channelData={item}
                tvSlug={tvSlug}
                onPathChange={() =>
                  this.setState({
                    path: window.location.pathname,
                  })
                }
                key={index}
              />
            );
          })}

        {/* Channel sub video list */}
        {tvSlug !== "rts-2" && (
          <section className="video-inner-section selection-tv section-mt">
            <Container>
              <Grid container className="flash-title">
                {channel ? (
                  <Grid item xs={12} className="grey-back">
                    <BlockTitle
                      title={`DERNIERES EPISODES | ${channel.title}`}
                    />
                    <div className="video-sub my-1">
                      <div className="row">
                        {videos &&
                        videos.length > 0 &&
                        this.state.loadingStatus === 1 ? (
                          videos.map((video, i) => (
                            <div className="large-3 medium-3 columns" key={i}>
                              <Link
                                to={"/dernierevideo/" + video.slug}
                                onClick={() =>
                                  this.setState({
                                    path: window.location.pathname,
                                  })
                                }>
                                <div className="video-duration">
                                  <span>2:33</span>
                                </div>
                                <div className="video-thumb">
                                  <img src={video.logo} alt="" title="" />
                                </div>
                                <div className="play-btn">
                                  <img src={playbutton} alt="" title="" />
                                </div>
                                <p className="page-title">{video.title}</p>
                              </Link>
                            </div>
                          ))
                        ) : (
                          <LoadingSpinner status={this.state.loadingStatus} />
                        )}
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Loading />
                )}
              </Grid>
            </Container>
          </section>
        )}
      </div>
    );
  }
}

export default TVPage;
