import React, { useEffect, useState } from "react";
import BlockTitle from "./BlockTitle";
import { Grid, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import RadioLogo from "../Assets/images/radio.png";
import LoadingSpinner from "./LoadingSpinner";

const PodcastRadio = ({ url, title }) => {
  const [radios, setRadios] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(1);

  useEffect(() => {
    if (url) {
      setLoadingStatus(3);
      axios.get(url).then((res) => {
        const radios = res.data.allitems;
        if (radios.length) {
          setLoadingStatus(1);
          setRadios(radios);
        } else {
          setRadios([]);
          setLoadingStatus(2);
        }
      });
    }
  }, [url]);

  return (
    <section className="selection-radio section-mt">
      <Container>
        <Grid container className="flash-title">
          <Grid item xs={12} className="grey-back">
            <BlockTitle title={title} />
            <Swiper
              className="video-swiper"
              spaceBetween={35}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // slidesPerView={4}
              rewind={true}
              navigation={true}
              breakpoints={{
                1300: {
                  slidesPerView: 6,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
              }}>
              {radios.length ? (
                radios.map((radio, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="">
                        <Link to={"/radios/dernierpodcast/" + radio.slug}>
                          <div className="cs-right">
                            <img
                              className="radio-img"
                              src={radio.logo || RadioLogo}
                              alt={radio.title}
                              title={radio.title}
                            />
                            <div className="video-desc py-0">
                              <div className="video-title w-100">
                                <h3 className="radio-title">{radio.title}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <LoadingSpinner status={loadingStatus} />
              )}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PodcastRadio;
